<script>
    import { onMount } from "svelte";

    import {
        getRefs,
        getGlobalKeyDownHandler,
        getGlobalKeyUpHandler,
    } from "../../helpers";
    import Button from "./Button";
    import FitplanIcon from "./icons/fitplan";
    import DiscoverIcon from "./icons/discover";
    import ProfileIcon from "./icons/profile";
    import SettingsIcon from "./icons/settings";
    import BackButton from "./BackButton";

    export let nav;
    const refs = getRefs();
    export let title;
    export let topKey;
    export let ariaTopKey;
    export let showBackButton = false;
    export let onBack = null;
    export let activeView = "";
    export let activeButton = "";

    const getFocusInHandler = name => () => {
        activeButton = name;
    };

    const goBack = () => {
        if (typeof onBack === "function") {
            onBack();
        } else {
            window.history.back();
        }
    };

    onMount(() => {
        refs["nav"] = {
            focus: () => {
                const el = refs[`nav.${activeButton || activeView}`];
                if (el) {
                    el.focus();
                } else {
                    const el = refs[`nav.${showBackButton ? "back" : "home"}`];
                    el && el.focus();
                }
            },
        };
    });
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.container {
  display: flex; }

.icons {
  flex: 0 0 auto; }

.separator {
  flex: 1 0 10px; }

.title {
  flex: 0 0 auto;
  text-align: right;
  font-family: Lato, sans-serif;
  font-size: 48px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #f5f5f5; }

/*# sourceMappingURL=Nav.svelte.css.map */</style>

<div class="container">
    <div class="icons">
        {#if showBackButton}
            <BackButton
                on:click={goBack}
                right={topKey}
                down={topKey}
                ariaRight={ariaTopKey}
                ariaDown={ariaTopKey} />
        {:else}
            <Button
                on:click={() => nav.navigate('/')}
                primary
                refKey={'nav.home'}
                on:focusin={getFocusInHandler('home')}
                active={activeView === 'home'}
                right="nav.discover"
                down={topKey}
                ariaDown={ariaTopKey}
                let:asHidden>
                <FitplanIcon />
                <span use:asHidden>Home</span>
            </Button>
            <Button
                on:click={() => nav.navigate('/discover')}
                refKey={'nav.discover'}
                on:focusin={getFocusInHandler('discover')}
                active={activeView === 'discover'}
                left="nav.home"
                right="nav.profile"
                down={topKey}
                ariaDown={ariaTopKey}
                let:asHidden>
                <DiscoverIcon />
                <span use:asHidden>Discover Fitplans</span>
            </Button>
            <Button
                on:click={() => nav.navigate('/profile')}
                refKey={'nav.profile'}
                on:focusin={getFocusInHandler('profile')}
                active={activeView === 'profile'}
                left="nav.discover"
                right="nav.settings"
                down={topKey}
                ariaDown={ariaTopKey}
                let:asHidden>
                <ProfileIcon />
                <span use:asHidden>User Profile</span>
            </Button>
            <Button
                on:click={() => nav.navigate('/settings')}
                refKey={'nav.settings'}
                on:focusin={getFocusInHandler('settings')}
                active={activeView === 'settings'}
                left="nav.profile"
                down={topKey}
                ariaDown={ariaTopKey}
                let:asHidden>
                <SettingsIcon />
                <span use:asHidden>Settings Menu</span>
            </Button>
        {/if}
    </div>
    <div class="separator" />
    {#if title}
        <div class="title">{title}</div>
    {/if}
</div>
<svelte:window
    on:keydown={getGlobalKeyDownHandler()}
    on:keyup={getGlobalKeyUpHandler(goBack)} />
