<script>
    import Content from "./Content";
    import SpinnerIcon from "./icons/Spinner";

    export let display = "fullscreen";
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.background {
  width: 100%;
  height: 100%; }

.grid {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-areas: ". . ." ". icon ." ". . .";
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 1fr auto 1fr; }
  .grid .icon {
    grid-area: icon; }

/*# sourceMappingURL=Spinner.svelte.css.map */</style>

{#if display === 'fullscreen'}
    <div class="background">
        <Content>
            <SpinnerIcon />
        </Content>
    </div>
{:else if display === 'grid'}
    <div class="grid">
        <div class="icon">
            <SpinnerIcon />
        </div>
    </div>
{:else}
    <SpinnerIcon />
{/if}
