<script>
    import { onMount, tick } from "svelte";

    import { initRefs, initFocus } from "../../helpers";
    import Container from "../../components/container";
    import Input from "../../components/Input";
    import Button from "../../components/Button";
    import qrcode from "qrcode";

    import Logo from "./Logo.png";

    export let nav;

    const refs = initRefs();

    const onBack = () => window.history.back();

    let number;
    let message = "Message sent!";

    const onSubmit = async () => {
        console.log("UNIMPLEMENTED!");
    };

    onMount(async () => {
        let url = "https://fitplan.app.link/Fcc4xICos4";
        // TODO: Reenable query once we can generate branch links
        // try {
        //     const search = window.location.search;
        //     if (search) {
        //         const args = search
        //             .toString()
        //             .replace("?", "")
        //             .split("&");
        //         for (const arg of args) {
        //             const [name, value] = arg.split("=");
        //             if (name === "link" && value.length > 0) {
        //                 url = `https://www.fitplanapp.com/?samsungBranch=${value}`;
        //             }
        //         }
        //     }
        // } catch (err) {}
        await tick();
        qrcode.toCanvas(
            document.getElementById("qr-code-canvas"),
            url,
            {
                width: 400,
                height: 400,
            },
            function(error) {
                if (error) setCurrentError(error, refs);
            }
        );
    });

    initFocus(refs, "nav.back", 1000);
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.container {
  position: fixed;
  top: 110px;
  height: 942px;
  width: 720px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  font-family: Lato, sans-serif;
  font-size: 32px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #bfbfbf;
  text-align: center; }
  .container .grid {
    display: grid;
    width: 100%;
    grid-template-areas: "icon title";
    grid-template-rows: auto;
    grid-template-columns: auto 1fr;
    grid-gap: 32px; }
    .container .grid .icon {
      grid-area: icon; }
    .container .grid .title {
      grid-area: title; }
  .container .icon {
    width: 120px;
    height: 120px;
    object-fit: contain; }
  .container .title {
    display: inline-block;
    font-family: Lato, sans-serif;
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #f5f5f5;
    text-align: left;
    align-self: center; }
  .container .subtitle {
    margin-top: 76px;
    margin-bottom: 76px; }
  .container canvas {
    display: inline-block;
    width: 400px;
    height: 400px;
    margin: auto;
    background-color: white; }

/*# sourceMappingURL=link.svelte.css.map */</style>

<Container
    {nav}
    topKey="input"
    {onBack}
    showBackButton
    backgroundImage="https://images.fitplanapp.com:443/tr:w-1920/web/hero/mike-desktop-banner.png">
    <div class="container">
        <form on:submit|preventDefault={onSubmit}>
            <div class="grid">
                <img class="icon" src={Logo} alt="Fitplan Logo" />
                <div class="title">
                    To start mobile-only Fitplans, open your Fitplan app.
                </div>
            </div>
            <div class="subtitle">
                Don’t have the app? Scan this QR code with your camera for a
                download link!
            </div>
            <canvas id="qr-code-canvas" />
        </form>
    </div>
</Container>
