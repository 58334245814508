<script>
    import { afterUpdate } from "svelte";

    import CompletedIcon from "../icons/CompletedIcon";
    import Image from "../Image";

    export let entry;
    export let focused;
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity 0.2s ease;
  opacity: 0; }
  .mask.img {
    background-color: rgba(17, 18, 19, 0.45); }
  .mask.text {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 55%, rgba(17, 18, 19, 0.6) 80%, rgba(17, 18, 19, 0.8)); }
  .mask.active {
    opacity: 1; }

.container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  border-radius: 24px; }
  .container.completed :global(.carousel-content-lazy-image) {
    opacity: 0.8; }
  .container :global(.carousel-content-lazy-image) {
    height: 400px;
    object-fit: cover;
    object-position: center; }

.title {
  font-family: Lato, sans-serif;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #fff;
  font-family: Barlow, sans-serif;
  font-stretch: condensed;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
  text-align: center;
  text-shadow: 0 2px 10px rgba(17, 18, 19, 0.3), 0 2px 15px rgba(17, 18, 19, 0.91); }

.subtitle {
  font-family: Lato, sans-serif;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #f5f5f5;
  letter-spacing: 1.5px;
  margin-top: 14px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap; }

.mobile-only {
  position: absolute;
  right: 20px;
  top: 20px;
  height: 28px;
  border-radius: 4px;
  background-color: #fed600;
  padding: 4px 8px 0 8px;
  font-family: Lato, sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #fff;
  color: #262626; }

.completed-icon {
  position: absolute;
  top: 24px;
  left: 24px; }

/*# sourceMappingURL=Content.svelte.css.map */</style>

<div class="container" class:completed={entry.completed}>
    <Image
        showSpinner
        className="carousel-content-lazy-image"
        src={entry.imageUrl}
        alt={entry.title} />
    <div class="mask text" class:active={focused} />
    <div class="title" class:active={focused}>
        {entry.title}
        {#if entry.subtitle}
            <div class="subtitle" class:active={focused}>{entry.subtitle}</div>
        {/if}
    </div>
    <div class="mask img" class:active={!focused} />
    {#if entry.mobileOnly}
        <div class="mobile-only">Mobile Only</div>
    {/if}
    {#if entry.completed}
        <div class="completed-icon">
            <CompletedIcon />
        </div>
    {/if}
</div>
