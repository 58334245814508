<script>
    import { focusRef, getRefs, initAria } from "../helpers";

    export let text;
    export let up;
    export let down;
    export let left;
    export let right;

    const refs = getRefs();
    export let refKey;

    let expanded = false;
    let shortHeight;
    let longHeight;
    const { label, labelledBy, asLabel, asHidden } = initAria();
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.container {
  background: none;
  outline: none;
  border: none;
  box-shadow: none;
  position: relative;
  font-family: Lato, sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #fff;
  line-height: 1.43;
  color: #bfbfbf;
  margin-bottom: 80px;
  text-align: left; }
  .container:hover, .container:focus, .container:active {
    background: none;
    outline: none;
    border: none;
    box-shadow: none; }
  .container:focus .expand {
    -webkit-transform: scale(1.2); }
    .container:focus .expand .bg {
      opacity: 1; }

.expand {
  color: #29db57;
  text-align: center;
  position: relative;
  margin-top: 8px;
  transition: all 0.2s ease-in-out; }
  .expand .bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    filter: blur(3px);
    opacity: 0;
    transition: opacity 0.2s ease-in-out; }

.short {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical; }
  .short.expanded {
    display: none; }

.long {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  visibility: hidden; }

.text {
  display: none; }
  .text.expanded {
    display: block; }

/*# sourceMappingURL=InfoBlock.svelte.css.map */</style>

<button
    aria-label={$label}
    aria-labelledby={$labelledBy}
    class="container"
    bind:this={refs[refKey]}
    on:focusin={event => {
        if (!(longHeight > shortHeight) && event.relatedTarget) {
            if (event.relatedTarget === refs[down]) {
                refs[up] && refs[up].focus();
            } else {
                refs[down] && refs[down].focus();
            }
        }
    }}
    on:click={() => (expanded = !expanded)}
    on:keyup={event => {
        if (event.key === 'ArrowDown') {
            const parent = refs['scrollParent'].getBoundingClientRect();
            const content = refs['scrollContent'].getBoundingClientRect();
            if (content.bottom < parent.bottom) {
                focusRef(refs, down);
            }
        }
        if (event.key === 'ArrowUp') {
            if (refs['scrollParent'].scrollTop < 4) {
                focusRef(refs, up);
            }
        }
        if (event.key === 'ArrowLeft') {
            focusRef(refs, left);
        }
        if (event.key === 'ArrowRight') {
            focusRef(refs, right);
        }
    }}
    on:keydown={event => {
        if (event.key === 'ArrowDown') {
            const parent = refs['scrollParent'].getBoundingClientRect();
            const content = refs['scrollContent'].getBoundingClientRect();
            if (content.bottom < parent.bottom) {
                event.preventDefault();
            }
        }
        if (event.key === 'ArrowUp') {
            if (refs['scrollParent'].scrollTop < 4) {
                event.preventDefault();
            }
        }
    }}>
    <div bind:offsetHeight={shortHeight} class="short" class:expanded>
        {text}
    </div>
    <div bind:offsetHeight={longHeight} class="long" class:expanded>{text}</div>
    <div class="text" class:expanded>{text}</div>
    {#if longHeight > shortHeight}
        <div class="expand">
            <div class="bg">{expanded ? 'Show Less' : 'Show More'}</div>
            {expanded ? 'Show Less' : 'Show More'}
        </div>
    {/if}
</button>
