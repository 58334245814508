<script>
    import { onMount, createEventDispatcher } from "svelte";
    import differenceInSeconds from "date-fns/differenceInSeconds";

    import {
        getKeyUpHandler,
        getKeyDownHandler,
        sleep,
        getRefs,
        addRef,
        initAria,
    } from "../helpers";

    export let id;
    export let refKey;
    export let autofocus;
    export let up = "";
    export let down = "";
    export let left = "";
    export let right = "";
    export let ariaLeft = "";
    export let ariaRight = "";
    export let ariaUp = "";
    export let ariaDown = "";
    export let type = "button";
    export let className = "";
    export let fixedWidth = false;
    const refs = getRefs();
    const asRef = addRef();

    let focused = false;

    const dispatch = createEventDispatcher();

    if (autofocus) {
        onMount(async () => {
            await sleep(100);
            refs[refKey].focus();
        });
    }
    const { label, labelledBy, asLabel, asHidden } = initAria();
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.button {
  display: inline-block;
  padding: 28px 60px;
  cursor: pointer;
  position: relative;
  text-align: center;
  border-radius: 66px;
  border: none;
  outline: none;
  box-shadow: none;
  font-family: Lato, sans-serif;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #fff;
  line-height: 1;
  color: #bfbfbf;
  background-color: rgba(68, 68, 68, 0.8);
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  transition: all 0.2s ease-out; }
  .button.fixed-width {
    width: 400px; }
  .button:disabled {
    cursor: default;
    background: #ccc; }
  .button:focus {
    -webkit-transform: scale(1.05);
    border: none;
    outline: none;
    background-image: linear-gradient(55deg, #12b587, #29db57 100%);
    color: white;
    box-shadow: 0 5px 15px rgba(71, 82, 79, 0.1), 0 15px 50px rgba(41, 219, 87, 0.25); }

/*# sourceMappingURL=Button.svelte.css.map */</style>

<button
    {id}
    class={`button ${className}`}
    aria-label={$label}
    aria-labelledby={$labelledBy}
    {type}
    class:fixed-width={fixedWidth}
    use:asRef={refKey}
    focusable
    on:click
    on:keyup={event => {
        if (event.key === 'Enter') {
            refs[refKey].style.transform = '';
        }
        getKeyUpHandler(refs, {
            up,
            down,
            left,
            right,
            ariaUp,
            ariaDown,
            ariaLeft,
            ariaRight,
        })(event);
    }}
    on:keydown={event => {
        if (event.key === 'Enter') {
            refs[refKey].style.transform = 'scale(1)';
        }
        getKeyDownHandler(refs, {
            up,
            down,
            left,
            right,
            ariaUp,
            ariaDown,
            ariaLeft,
            ariaRight,
        })(event);
    }}
    on:focusin={() => (focused = true)}
    on:focusout={() => (focused = false)}>
    <slot {asLabel} {asHidden} />
</button>
