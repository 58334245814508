<script>
    import { tick, onMount } from "svelte";
    import { captureException } from "@sentry/browser";

    import { sleep, fieldValidators } from "../../helpers";
    import Spinner from "../../components/Spinner";
    import Container from "../../components/container";
    import Input from "../../components/Input";
    import GridButton from "../../components/grid/Button";
    import Button from "../../components/Button";
    import MaleIcon from "../../components/icons/SignupMale";
    import FemaleIcon from "../../components/icons/SignupFemale";
    import OtherIcon from "../../components/icons/SignupOther";
    import CheckmarkIcon from "../../components/icons/Checkmark";

    import Logo from "./logo.png";

    import {
        signup,
        initRefs,
        initFocus,
        getCachedValue,
        setCachedValue,
        clearCache,
        setError,
        setCurrentError,
        replaceLocation,
    } from "../../helpers";

    export let nav;

    let pending = false;
    let username = "";
    let password = "";
    let confirmPassword = "";
    let gender = "";
    let error = "";

    let usernameEl;
    let passwordEl;
    let buttonEl;

    let completed = [];
    let remaining = ["email", "password", "confirmPassword", "gender"];

    const refs = initRefs();

    const focus = () => {
        if (remaining[0] === "gender") {
            refs[gender || "female"].focus();
        } else {
            refs[remaining[0]].focus();
        }
    };

    const save = async () => {
        const values = {
            username,
            password,
            confirmPassword,
            gender,
            completed,
            remaining,
        };
        await setCachedValue("signup-form", values);
    };

    const onClick = async () => {
        if (pending) {
            return;
        }
        await save();
        if (remaining[0] === "confirmPassword") {
            if (password !== confirmPassword) {
                error = "Passwords do not match";
            }
        } else if (remaining[0] === "gender") {
            error = fieldValidators["gender"](gender);
        } else {
            error = fieldValidators[remaining[0]](
                remaining[0] === "email" ? username : password
            );
        }
        try {
            refs[remaining[0]].blur();
        } catch (err) {}
        if (remaining.length === 1 && !error) {
            pending = true;
            try {
                const result = await signup(username, password, gender);
                await clearCache();
                await setCachedValue("accessToken", result, 365);
                replaceLocation(nav, "/");
            } catch (err) {
                captureException(err);
                if (err && err.serverMessage) {
                    error = err.serverMessage;
                } else {
                    setCurrentError(err, refs, "nav");
                    console.error(err);
                }
            }
            pending = false;
        } else if (!error) {
            const [completedEl, ...rest] = remaining;
            completed = [...completed, completedEl];
            // Delay to make animation smoother
            await sleep(200);
            remaining = [...rest];
            await tick();
            focus();
        } else {
            focus();
        }
    };

    const onBack = () => {
        if (completed.length > 0) {
            const target = completed.slice(-1)[0];
            // Delay to make animation smoother
            remaining = [target, ...remaining];
            sleep(200).then(async () => {
                completed = completed.slice(0, completed.length - 1);
                await tick();
                focus();
            });
        } else {
            replaceLocation(nav, "/splash");
        }
    };

    onMount(async () => {
        const values = await getCachedValue("signup-form");
        if (values) {
            username = values.username || "";
            password = values.password || "";
            confirmPassword = values.confirmPassword || "";
            gender = values.gender || "";
            completed = values.completed || completed;
            remaining = values.remaining || remaining;
        }
        await sleep(100);
        focus();
    });

    refs["input"] = {
        focus,
    };
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.container {
  position: relative;
  height: 800px;
  width: 100%; }
  .container .hidden-submit {
    visibility: hidden; }

.logo {
  margin-top: 24px;
  display: inline-block;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%); }

.field {
  position: absolute;
  top: 32px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  font-family: Lato, sans-serif;
  font-size: 30px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #fff;
  text-align: center;
  transition: opacity 0.8s ease;
  opacity: 0; }
  .field.completed {
    opacity: 0; }
  .field.current {
    opacity: 1; }
  .field .title {
    margin-bottom: 32px; }
  .field .error {
    margin-top: 24px;
    font-family: Lato, sans-serif;
    font-size: 24px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #fc172f; }

.gender-container {
  display: inline-block;
  width: 240px;
  height: 240px;
  margin: 24px 16px; }

.gender-btn {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: rgba(81, 81, 81, 0.5);
  position: relative;
  font-family: Lato, sans-serif;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #95979a;
  text-align: center;
  transition: all 0.2s ease; }
  .gender-btn.focused {
    background-color: #515151;
    color: #fff;
    -webkit-transform: scale(1.05); }
  .gender-btn .checkmark {
    position: absolute;
    top: 24px;
    right: 24px;
    opacity: 0; }
    .gender-btn .checkmark.active {
      opacity: 1; }
  .gender-btn .gender-content {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%); }
    .gender-btn .gender-content .icon {
      margin-bottom: 24px; }

.submit {
  display: inline-block;
  margin: 96px auto 0 auto; }
  .submit span {
    margin-right: 32px; }

/*# sourceMappingURL=signup.svelte.css.map */</style>

<Container
    {nav}
    topKey="input"
    {onBack}
    showBackButton
    backgroundImage="https://images.fitplanapp.com:443/tr:w-1920/web/hero/mike-desktop-banner.png">
    <form on:submit|preventDefault={onClick} novalidate>
        <div class="logo">
            <img src={Logo} alt={'Fitplan Logo'} />
        </div>
        <div class="container">
            <div
                class="field"
                class:current={remaining[0] === 'email'}
                class:completed={completed.indexOf('email') > -1}>
                <div class="title">Enter your email</div>
                <Input
                    bind:element={usernameEl}
                    tabindex={1}
                    up="nav"
                    type="email"
                    refKey="email"
                    autocomplete="off"
                    placeholder="Email Address"
                    bind:value={username} />
                {#if error}
                    <div class="error">{error}</div>
                {/if}
            </div>
            <div
                class="field"
                class:current={remaining[0] === 'password'}
                class:completed={completed.indexOf('password') > -1}>
                <div class="title">Enter your password</div>
                <Input
                    bind:element={passwordEl}
                    up="nav"
                    tabindex={2}
                    type="password"
                    refKey="password"
                    placeholder="Password"
                    bind:value={password} />
                {#if error}
                    <div class="error">{error}</div>
                {/if}
            </div>
            <div
                class="field"
                class:current={remaining[0] === 'confirmPassword'}
                class:completed={completed.indexOf('confirmPassword') > -1}>
                <div class="title">Confirm your password</div>
                <Input
                    bind:element={passwordEl}
                    up="nav"
                    tabindex={2}
                    type="password"
                    refKey="confirmPassword"
                    placeholder="Confirm Password"
                    bind:value={confirmPassword} />
                {#if error}
                    <div class="error">{error}</div>
                {/if}
            </div>
            <div
                class="field"
                class:current={remaining[0] === 'gender'}
                class:completed={completed.indexOf('gender') > -1}>
                <div class="title">Who do you want to workout with?</div>
                <div class="gender-btns">
                    <div class="gender-container">
                        <GridButton
                            on:click={async () => {
                                gender = 'female';
                                await save();
                            }}
                            refKey="female"
                            left="other"
                            right="male"
                            up="nav"
                            down="tos"
                            let:focused>
                            <div class="gender-btn" class:focused>
                                <div
                                    class="checkmark"
                                    class:active={gender === 'female'}>
                                    <CheckmarkIcon />
                                </div>
                                <div class="gender-content">
                                    <div class="icon">
                                        <FemaleIcon />
                                    </div>
                                    Female
                                </div>
                            </div>
                        </GridButton>
                    </div>
                    <div class="gender-container">
                        <GridButton
                            on:click={async () => {
                                gender = 'male';
                                await save();
                            }}
                            refKey="male"
                            left="female"
                            right="other"
                            up="nav"
                            down="tos"
                            let:focused>
                            <div class="gender-btn" class:focused>
                                <div
                                    class="checkmark"
                                    class:active={gender === 'male'}>
                                    <CheckmarkIcon />
                                </div>
                                <div class="gender-content">
                                    <div class="icon">
                                        <MaleIcon />
                                    </div>
                                    Male
                                </div>
                            </div>
                        </GridButton>
                    </div>
                    <div class="gender-container">
                        <GridButton
                            on:click={async () => {
                                gender = 'other';
                                await save();
                            }}
                            refKey="other"
                            left="male"
                            right="female"
                            up="nav"
                            down="tos"
                            let:focused>
                            <div class="gender-btn" class:focused>
                                <div
                                    class="checkmark"
                                    class:active={gender === 'other'}>
                                    <CheckmarkIcon />
                                </div>
                                <div class="gender-content">
                                    <div class="icon">
                                        <OtherIcon />
                                    </div>
                                    Both
                                </div>
                            </div>
                        </GridButton>
                    </div>
                </div>
                {#if error}
                    <div class="error">{error}</div>
                {/if}
                <div class="submit">
                    <span>
                        <Button
                            on:click={() => nav.navigate('/settings/tos')}
                            refKey="tos"
                            fixedWidth
                            down="signup"
                            right="privacy"
                            up="input">
                            Terms Of Service
                        </Button>
                    </span>
                    <Button
                        on:click={() => nav.navigate('/settings/privacy')}
                        refKey="privacy"
                        fixedWidth
                        down="signup"
                        left="tos"
                        up="input">
                        Privacy Policy
                    </Button>
                </div>
                <div class="submit">
                    <Button refKey="signup" fixedWidth type="submit" up="input">
                        Sign Up
                    </Button>
                </div>
            </div>

            <div class="hidden-submit">
                <button focusable="false" type="submit">Submit</button>
            </div>
        </div>
    </form>
</Container>
{#if pending}
    <Spinner />
{/if}
