<script>
    import { onMount } from "svelte";

    import Container from "../../components/container";
    import Button from "../../components/grid/Button";
    import ButtonContent from "../../components/settings/ButtonContent";
    import FAQIcon from "../../components/icons/FAQIcon";
    import TOSIcon from "../../components/icons/TOSIcon";
    import PrivacyPolicyIcon from "../../components/icons/PrivacyPolicyIcon";
    import LinkIcon from "../../components/icons/LinkIcon";
    import LogOutIcon from "../../components/icons/LogOutIcon";
    import { initFocus, initRefs, sleep, clearCache } from "../../helpers";

    export let nav;

    const refs = initRefs();

    let activeBtn = "fitplans";
    let focused = false;

    initFocus(refs, "nav", 1000);

    onMount(async () => {
        refs["settings"] = {
            focus: () => {
                const el = refs[`settings.${activeBtn}`];
                if (el && el.focus) {
                    el.focus();
                } else {
                    refs[`settings.faq`].focus();
                }
            },
        };
    });

    const focusIn = name => () => {
        focused = true;
        activeBtn = name;
    };

    const focusOut = () => (focused = false);
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.container {
  display: grid;
  grid-template-areas: ". .   .    .   .       .      ." ". faq link tos privacy logout ." ". .   .    .   .       .      .";
  grid-template-columns: 1fr repeat(5, 320px) 1fr;
  grid-template-rows: 1fr 380px 1fr;
  height: 100%;
  grid-gap: 0 32px;
  align-content: center; }
  .container .faq {
    grid-area: faq; }
  .container .link {
    grid-area: link; }
  .container .tos {
    grid-area: tos; }
  .container .privacy {
    grid-area: privacy; }
  .container .logout {
    grid-area: logout; }

/*# sourceMappingURL=index.svelte.css.map */</style>

<Container
    {nav}
    title="My Profile"
    topKey="settings"
    activeView="settings"
    backgroundImage="https://images.fitplanapp.com:443/tr:w-1920/web/hero/mike-desktop-banner.png">
    <div class="container">
        <div class="faq">
            <Button
                on:focus={focusIn('faq')}
                on:click={() => nav.navigate('/settings/faq')}
                on:blur={focusOut}
                refKey={`settings.faq`}
                autofocus
                left="settings.logout"
                right="settings.link"
                up="nav">
                <ButtonContent text={'FAQ'} focused={activeBtn === 'faq'}>
                    <FAQIcon />
                </ButtonContent>
            </Button>
        </div>
        <div class="link">
            <Button
                on:focus={focusIn('link')}
                on:click={() => nav.navigate('/settings/link')}
                on:blur={focusOut}
                refKey={`settings.link`}
                left="settings.faq"
                right="settings.tos"
                largeMargin={true}
                up="nav">
                <ButtonContent
                    text={'Check Out Fitplan'}
                    focused={activeBtn === 'link'}>
                    <LinkIcon />
                </ButtonContent>
            </Button>
        </div>
        <div class="tos">
            <Button
                on:focus={focusIn('tos')}
                on:click={() => nav.navigate('/settings/tos')}
                on:blur={focusOut}
                refKey={`settings.tos`}
                left="settings.link"
                right="settings.privacy"
                up="nav">
                <ButtonContent
                    text={'Terms of Service'}
                    focused={activeBtn === 'tos'}>
                    <TOSIcon />
                </ButtonContent>
            </Button>
        </div>
        <div class="privacy">
            <Button
                on:focus={focusIn('privacy')}
                on:click={() => nav.navigate('/settings/privacy')}
                on:blur={focusOut}
                refKey={`settings.privacy`}
                left="settings.tos"
                right="settings.logout"
                up="nav">
                <ButtonContent
                    text={'Privacy Policy'}
                    focused={activeBtn === 'privacy'}>
                    <PrivacyPolicyIcon />
                </ButtonContent>
            </Button>
        </div>
        <div class="logout">
            <Button
                on:focus={focusIn('logout')}
                on:click={async () => {
                    await clearCache();
                    nav.navigate('/splash');
                }}
                on:blur={focusOut}
                refKey={`settings.logout`}
                left="settings.privacy"
                right="settings.faq"
                isLogout={true}
                up="nav">
                <ButtonContent
                    text={'Log Out'}
                    focused={activeBtn === 'logout'}>
                    <LogOutIcon />
                </ButtonContent>
            </Button>
        </div>
        <slot />
    </div>
</Container>
