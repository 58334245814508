<script>
    import { onMount } from "svelte";
    import { getKeyUpHandler, sleep, getRefs } from "../helpers";

    export let type;
    export let value;
    export let autofocus;
    export let tabindex;
    export let required;
    export let placeholder;
    export let autocomplete;
    const refs = getRefs();
    export let refKey;

    onMount(async () => {
        if (autofocus) {
            await sleep(100);
            refs[refKey].focus();
        }
    });
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.input {
  display: block;
  outline: none;
  box-sizing: border-box;
  border: none;
  width: 460px;
  border-radius: 8px;
  -webkit-backdrop-filter: blur(9px);
  backdrop-filter: blur(9px);
  background-color: rgba(105, 105, 105, 0.5);
  font-family: Lato, sans-serif;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #fff; }
  .input:focus, .input:active {
    border-bottom: 1px solid rgba(58, 59, 62, 0.3); }
  .input::placeholder {
    font-family: Lato, sans-serif;
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #8c8c8c; }
  .input:disabled {
    border-bottom: 1px dotted #757575; }
  .input:invalid {
    border-bottom: 1px solid #fc172f; }

/*# sourceMappingURL=Input.svelte.css.map */</style>

{#if type === 'password'}
    <input
        bind:this={refs[refKey]}
        on:keyup={getKeyUpHandler(refs, $$props)}
        focusable
        {tabindex}
        class="input"
        {required}
        {placeholder}
        {autocomplete}
        type="password"
        bind:value />
{:else if type === 'email'}
    <input
        bind:this={refs[refKey]}
        on:keyup={getKeyUpHandler(refs, $$props)}
        {tabindex}
        class="input"
        {required}
        {placeholder}
        {autocomplete}
        type="email"
        bind:value />
{:else if type === 'tel'}
    <input
        bind:this={refs[refKey]}
        on:keyup={getKeyUpHandler(refs, $$props)}
        {tabindex}
        class="input"
        {required}
        {placeholder}
        {autocomplete}
        type="tel"
        bind:value />
{:else}
    <input
        bind:this={refs[refKey]}
        on:keyup={getKeyUpHandler(refs, $$props)}
        focusable
        {tabindex}
        class="input"
        {required}
        {placeholder}
        {autocomplete}
        type="text"
        bind:value />
{/if}
