<script>
    import { createEventDispatcher, onMount } from "svelte";

    import { sleep, getRefs } from "../../helpers";

    import Tab from "./Tab";

    const refs = getRefs();
    export let refBase = "";
    export let up = "";
    export let down = "";
    export let activeTab;
    export let autofocus;

    onMount(async () => {
        await sleep(100);
        refs[refBase] = {
            focus: () => refs[`${refBase}.${activeTab}`].focus(),
        };
    });

    const dispatch = createEventDispatcher();
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.container {
  display: block; }

/*# sourceMappingURL=Tabs.svelte.css.map */</style>

<div class="container">
    <Tab
        {up}
        {down}
        autofocus={autofocus && activeTab === 'fitplans'}
        active={activeTab === 'fitplans'}
        refKey={`${refBase}.fitplans`}
        right={`${refBase}.workouts`}
        title="My Fitplans"
        on:focusin={() => {
            activeTab = 'fitplans';
            dispatch('change', activeTab);
        }} />
    <Tab
        {up}
        {down}
        autofocus={autofocus && activeTab === 'workouts'}
        active={activeTab === 'workouts'}
        refKey={`${refBase}.workouts`}
        left={`${refBase}.fitplans`}
        title="My Workouts"
        on:focusin={() => {
            activeTab = 'workouts';
            dispatch('change', activeTab);
        }} />
</div>
