<script>
    import {} from "svelte";

    import DataLoader from "../DataLoader";
    import Spinner from "../Spinner";

    const getValue = val =>
        val > 1000
            ? `${(val / 1000).toFixed(val > 10000 ? 0 : 1)}k`
            : `${val.toFixed()}`;
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.container {
  display: grid;
  grid-template-areas: "v1 v2 v3 ." "n1 n2 n3 .";
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto auto;
  grid-gap: 5px;
  text-align: center; }
  .container .v1 {
    grid-area: v1; }
  .container .v2 {
    grid-area: v2; }
  .container .v3 {
    grid-area: v3; }
  .container .n1 {
    grid-area: n1; }
  .container .n2 {
    grid-area: n2; }
  .container .n3 {
    grid-area: n3; }
  .container .value {
    font-family: Lato, sans-serif;
    font-size: 64px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #f5f5f5; }
  .container .name {
    font-family: Lato, sans-serif;
    font-size: 24px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #8c8c8c; }

/*# sourceMappingURL=Stats.svelte.css.map */</style>

<DataLoader path="/v2/user/stats" cacheKey="user-stats">
    <div class="container" slot="data" let:data={stats}>
        <div class="name n1">Training Mins</div>
        <div class="name n2">Calories Burned</div>
        <div class="name n3">Workouts Done</div>

        <div class="value v1">{getValue(stats.trainingTime / 60)}</div>
        <div class="value v2">{getValue(stats.totalCaloriesBurned)}</div>
        <div class="value v3">{getValue(stats.workoutsCompleted)}</div>
    </div>
    <span slot="loading">
        <Spinner display="grid" />
    </span>
</DataLoader>
