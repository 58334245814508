<script>
    import { onMount, createEventDispatcher } from "svelte";
    import { fade } from "svelte/transition";
    import {
        focusRef,
        isInViewport,
        sleep,
        getRefs,
        initAria,
    } from "../../helpers";

    const dispatch = createEventDispatcher();

    const refs = getRefs();
    export let refKey;
    export let autofocus = false;
    export let className = "";
    export let up = "";
    export let down = "";
    export let left = "";
    export let right = "";
    export let hidden = false;

    let focused = false;

    onMount(async () => {
        if (autofocus) {
            await sleep(100);
            refs[refKey] && refs[refKey].focus();
        }
    });
    const { label, labelledBy, asLabel, asHidden } = initAria();
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.button {
  padding: 0;
  margin: 0;
  position: relative;
  object-fit: cover;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: none;
  border: none;
  outline: none;
  border-radius: 8px;
  transition: all 0.2s ease-in-out; }
  .button:focus {
    border: none;
    outline: none; }
  .button.focused {
    z-index: 1000; }

/*# sourceMappingURL=Button.svelte.css.map */</style>

<button
    class={`button ${className}`}
    aria-label={$label}
    aria-labelledby={$labelledBy}
    class:focused
    on:click={event => {
        if (!hidden) {
            dispatch('click', event);
        }
    }}
    transition:fade
    on:keyup={event => {
        if (hidden) {
            event.preventDefault();
            dispatch('refresh');
            return;
        }
        if (event.key === 'ArrowDown') {
            focusRef(refs, down);
        }
        if (event.key === 'ArrowUp') {
            focusRef(refs, up);
        }
        if (event.key === 'ArrowLeft') {
            focusRef(refs, left);
        }
        if (event.key === 'ArrowRight') {
            focusRef(refs, right);
        }
        dispatch('refresh');
    }}
    on:keydown={event => {
        if (hidden) {
            event.preventDefault();
            return;
        }
        if (event.key === 'ArrowDown' && refs[down]) {
            event.preventDefault();
        }
        if (event.key === 'ArrowUp' && refs[up]) {
            event.preventDefault();
        }
        if (event.key === 'ArrowLeft') {
            dispatch('backwardDown');
            event.preventDefault();
        }
        if (event.key === 'ArrowRight') {
            dispatch('forwardDown');
            event.preventDefault();
        }
        dispatch('refresh');
    }}
    on:focusin={event => {
        focused = true;
        const res = isInViewport(refs[refKey]);
        if (!res) {
            refs[refKey].scrollIntoView();
        }
        dispatch('focus', event);
    }}
    on:focusout={event => {
        focused = false;
        dispatch('blur', event);
    }}
    bind:this={refs[refKey]}>
    <slot {focused} {asLabel} {asHidden} />
</button>
