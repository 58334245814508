<script>
    export let focused;
    export let text;
    export let isLogout = false;
    export let extraMargin = false;
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.more {
  position: relative;
  width: 100%;
  height: 100%; }
  .more .mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity 0.2s ease-in-out; }
  .more .bg {
    border-radius: 8px;
    background: rgba(68, 68, 68, 0.7);
    opacity: 1; }
    .more .bg.active {
      opacity: 0.4; }
  .more .bg2 {
    -webkit-filter: blur(11px);
    opacity: 0; }
    .more .bg2.normal {
      background: linear-gradient(45deg, #12b587, #29db57); }
    .more .bg2.isLogout {
      background: linear-gradient(45deg, #ff006e, #f1004c); }
    .more .bg2.active {
      opacity: 1; }
  .more .text {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    text-align: center;
    font-family: Lato, sans-serif;
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #f5f5f5;
    width: 100%; }
    .more .text .icon {
      margin: auto;
      position: relative;
      transition: opacity 0.2s ease-in-out;
      margin-bottom: 24px; }
      .more .text .icon.extraMargin {
        margin-bottom: 48px; }

/*# sourceMappingURL=ButtonContent.svelte.css.map */</style>

<div class="more">
    <div
        class="bg2 mask"
        class:active={focused}
        class:normal={!isLogout}
        class:isLogout />
    <div class="bg mask" class:active={focused} />
    <div class="text">
        <div class="icon" class:extraMargin>
            <slot />
        </div>
        {text}
    </div>
</div>
