<script>
    import { onDestroy, onMount } from "svelte";

    import Button from "../../components/Button";
    import Background from "../../components/Background";
    import {
        getCachedValue,
        getGlobalKeyUpHandler,
        deleteCachedValue,
        initRefs,
        initFocus,
        setVoiceIntro,
        replaceLocation,
    } from "../../helpers";

    import LogoText from "./logo-text.png";
    import BackgroundImage from "./background.jpg";

    export let nav;

    onMount(async () => {
        await deleteCachedValue("signup-form");
        const data = await getCachedValue("accessToken");
        if (data) {
            nav.navigate("/");
        }
    });

    const refs = initRefs();
    initFocus(refs, "code");
    const asIntro = setVoiceIntro();
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.container {
  padding-top: 140px;
  padding-left: 140px; }
  .container img {
    width: 426px; }
  .container h1 {
    font-family: Lato, sans-serif;
    font-size: 40px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #ffffff;
    margin-top: 43px; }
  .container .login {
    margin-top: 254px; }
    .container .login span {
      margin-right: 40px; }
  .container .text {
    font-family: Lato, sans-serif;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #d9d9d9;
    margin-top: 80px; }
  .container .signup {
    margin-top: 34px; }

/*# sourceMappingURL=index.svelte.css.map */</style>

<Background imageUrl={BackgroundImage} />

<div class="container">
    <span role="heading" use:asIntro>
        <img src={LogoText} alt={'Fitplan'} />
        <h1>Your personal fitness trainer at Gym & Home</h1>
        <p class="hidden-intro">
            . You must sign in or register to use Fitplan. You can do so using
            your mobile phone, computer, or TV. Press any arrow key to begin.
        </p>
    </span>
    <div class="login">
        <span>
            <Button
                refKey="code"
                down="signup"
                right="login"
                on:click={() => replaceLocation(nav, '/login/code')}
                id="login-btn">
                Sign in on mobile device
            </Button>
        </span>
        <Button
            refKey="login"
            down="signup"
            left="code"
            on:click={() => replaceLocation(nav, '/login')}
            id="login-btn">
            Sign in on TV
        </Button>
    </div>
    <div class="text">New to Fitplan? Create an account</div>
    <div class="signup">
        <Button
            refKey="signup"
            up="code"
            on:click={() => replaceLocation(nav, '/signup')}>
            Create Account
        </Button>
    </div>
</div>
<svelte:window on:keyup={getGlobalKeyUpHandler(() => {})} />
