<script>
    export let imageUrl;
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 1920px;
  height: 1080px;
  object-fit: cover;
  z-index: -1; }
  .cover img {
    height: 1080px; }

.mask {
  background-image: linear-gradient(299deg, rgba(39, 42, 43, 0.77), rgba(17, 18, 19, 0.87)); }

/*# sourceMappingURL=Background.svelte.css.map */</style>

<div class="cover background">
    <img src={imageUrl} alt="background" />
</div>
<div class="cover mask" />
