<script>
    import { onMount } from "svelte";

    import {
        getRefs,
        getGlobalKeyDownHandler,
        getGlobalKeyUpHandler,
    } from "../../helpers";
    import BackButton from "./BackButton";
    import Spinner from "../Spinner";
    import Error from "../Error";

    const refs = getRefs();
    export let topKey;
    export let backgroundImage;
    export let loading = false;
    export let goBack = () => window.history.back();
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.cover {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }
  .cover img {
    object-fit: cover;
    height: 100vh; }

.container {
  display: grid;
  grid-template-areas: ". .    info    .   video" ". nav  info    .   video" ". .    info    .   video" ". .    info    .   video";
  grid-template-columns: 72px auto 1fr 56px 1080px;
  grid-template-rows: 72px auto 1fr 72px;
  grid-gap: 24px;
  height: 100vh; }

.nav {
  grid-area: nav; }

.info {
  grid-area: info;
  font-family: Lato, sans-serif;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #fff;
  font-weight: 500;
  line-height: 1.43;
  color: #bfbfbf;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 172px 0 112px 0; }
  .info::-webkit-scrollbar {
    display: none; }

.video {
  grid-area: video;
  position: relative;
  overflow: hidden;
  height: 100vh; }

.mask {
  background-image: linear-gradient(299deg, rgba(39, 42, 43, 0.87), rgba(17, 18, 19, 0.97)); }

.spinner {
  position: relative;
  grid-row: 1 / span 4;
  grid-column: 3 / span 3; }

.info,
.video,
.spinner {
  transition: opacity 0.2s ease-in-out;
  opacity: 0; }
  .info.active,
  .video.active,
  .spinner.active {
    opacity: 1; }

/*# sourceMappingURL=VideoContainer.svelte.css.map */</style>

{#if backgroundImage}
    <div class="cover background">
        <img src={backgroundImage} alt="background" />
    </div>
    <div class="cover mask" />
{/if}
<div class="cover container">
    <div class="nav">
        <BackButton on:click={() => goBack()} down={topKey} />
    </div>
    <div class="info" class:active={!loading} bind:this={refs['scrollParent']}>
        <div bind:this={refs['scrollContent']}>
            <slot name="info" />
        </div>
    </div>
    <div class="video" class:active={!loading}>
        <slot name="video" />
    </div>
</div>
<div class="spinner" class:active={loading}>
    <Spinner />
</div>

<Error />

<svelte:window
    on:keydown={getGlobalKeyDownHandler()}
    on:keyup={getGlobalKeyUpHandler(goBack)} />
