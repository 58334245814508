<script>
    import { createEventDispatcher, onMount, afterUpdate } from "svelte";
    const dispatch = createEventDispatcher();

    import Button from "./Button";
    import Spinner from "../Spinner";
    import { getRefs } from "../../helpers";
    import BackButton from "../container/BackButton";
    import PauseIcon from "../icons/Pause";
    import PlayIcon from "../icons/Play";

    const HIDE_TIMEOUT = 2500;

    export let up;
    export let down;
    export let left;
    export let right;
    export let paused;
    export let duration;
    export let seeking;
    export let completed;
    export let currentTime;
    export let isPreview = false;
    export let loading;
    export let showBack = false;

    const refs = getRefs();
    let scrubber;
    let hidden = false;
    let hideTimeout;
    const show = () => {
        hidden = false;
        if (hideTimeout) {
            clearTimeout(hideTimeout);
        }
        hideTimeout = setTimeout(() => {
            hidden = true;
            hideTimeout = null;
        }, HIDE_TIMEOUT);
    };

    const getTime = time => {
        time = Math.floor(time);
        const seconds = time % 60;
        const minutes = (time - seconds) / 60;
        return `${minutes < 10 ? "0" : ""}${minutes}:${
            seconds < 10 ? "0" : ""
        }${seconds}`;
    };

    onMount(() => {
        show();
    });

    afterUpdate(() => {
        if (!loading && scrubber && duration) {
            scrubber.style.width = `${(100 * currentTime) / duration}%`;
        }
    });
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.mask {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-shadow: inset 0px 0px 36px 8px #111213;
  background-image: linear-gradient(to bottom, rgba(17, 18, 19, 0), rgba(17, 18, 19, 0.2) 80%, #111213 100%);
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
  z-index: 1001;
  display: grid;
  grid-template-areas: "back   .       .       .       ." ".      .       .       .       ." ".      .       play    .       ." ".      .       .       .       ." ".      info    info    info    .";
  grid-template-rows: auto 1fr 116px 1fr auto;
  grid-template-columns: auto 1fr 116px 1fr 64px;
  padding: 96px; }
  .mask.hidden {
    opacity: 0; }

.back {
  grid-area: back; }

.play {
  grid-area: play; }
  .play .active,
  .play .inactive {
    transition: opacity 0.2s ease-in-out;
    border-radius: 58px;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1002; }
  .play .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    z-index: 1503; }
    .play .icon.play {
      -webkit-transform: translate(calc(-50% + 4px), calc(-50% + 2px)); }
  .play .active {
    opacity: 0;
    background-image: linear-gradient(73deg, #12b587, #29db57); }
    .play .active.focused {
      opacity: 1; }
  .play .inactive {
    opacity: 1;
    background: rgba(17, 16, 17, 0.8); }
    .play .inactive.focused {
      opacity: 0; }
  .play.loading {
    display: none; }

.info {
  grid-area: info;
  justify-self: center;
  display: flex;
  flex-wrap: nowrap; }
  .info .time {
    display: inline-block;
    font-family: Lato, sans-serif;
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #fff;
    color: white;
    flex: 0 0 auto; }
  .info .progress {
    display: block;
    height: 6px;
    border-radius: 3px;
    background-color: rgba(200, 200, 200, 0.3);
    flex: 1 0 200px;
    min-width: 700px;
    margin: 14px 16px 0 16px;
    position: relative; }
    .info .progress div {
      background-color: #fff;
      border-radius: 3px;
      position: absolute;
      top: 0;
      left: 0;
      height: 6px; }

/*# sourceMappingURL=Controls.svelte.css.map */</style>

<div
    class="mask"
    class:hidden={(!isPreview && completed) || (!seeking && !paused && hidden)}>
    {#if showBack}
        <div class="back">
            <BackButton on:click={() => window.history.back()} down={'play'} />
        </div>
    {/if}
    {#if !loading}
        <div class="play" class:loading>
            <Button
                hidden={!completed && !seeking && !paused && hidden}
                refKey="play"
                {up}
                {down}
                {left}
                {right}
                autofocus={!isPreview}
                on:focus={() => show()}
                on:refresh={show}
                on:click={() => (paused ? dispatch('play') : dispatch('pause'))}
                let:focused>
                <div class="active" class:focused />
                <div class="inactive" class:focused />
                {#if paused}
                    <div class="play icon">
                        <PlayIcon />
                    </div>
                {:else}
                    <div class="icon">
                        <PauseIcon />
                    </div>
                {/if}
            </Button>
        </div>
        {#if !completed}
            <div class="info">
                <div class="time">{getTime(currentTime)}</div>
                <div class="progress">
                    <div bind:this={scrubber}>&nbsp;</div>
                </div>
                <div class="time">{getTime(duration)}</div>
            </div>
        {/if}
    {/if}
</div>
