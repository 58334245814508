<script>
    export let focused;
    export let text;

    import MoreIcon from "../icons/More";
    import MoreSelectedIcon from "../icons/MoreSelected";
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.more {
  position: relative;
  width: 100%;
  height: 100%; }
  .more .mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity 0.2s ease-in-out; }
  .more .bg {
    border-radius: 8px;
    background: rgba(68, 68, 68, 0.7);
    opacity: 1; }
    .more .bg.active {
      opacity: 0.4; }
  .more .bg2 {
    -webkit-filter: blur(11px);
    background: linear-gradient(45deg, #12b587, #29db57);
    opacity: 0; }
    .more .bg2.active {
      opacity: 1; }
  .more .text {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    text-align: center;
    font-family: Lato, sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #fff;
    color: #e8e8e8; }
    .more .text .icon {
      margin: auto;
      position: relative;
      transition: opacity 0.2s ease-in-out; }
    .more .text .icon-fade {
      opacity: 0; }
      .more .text .icon-fade.active {
        opacity: 1; }

/*# sourceMappingURL=MoreButton.svelte.css.map */</style>

<div class="more">
    <div class="bg2 mask" class:active={focused} />
    <div class="bg mask" class:active={focused} />
    <div class="text">
        <div class="icon">
            <div class="mask icon-fade" class:active={focused}>
                <MoreSelectedIcon />
            </div>
            <div class="icon-fade" class:active={!focused}>
                <MoreIcon />
            </div>
        </div>
        {text}
    </div>
</div>
