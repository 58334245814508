<script>
    import { getRefs } from "../../helpers";
    import Nav from "./Nav";
    import Error from "../Error";
    import Background from "../Background";

    export let nav;
    const refs = getRefs();
    export let title;
    export let topKey;
    export let ariaTopKey;
    export let backgroundImage;
    export let scrollParentKey = "";
    export let activeView = "";
    export let onBack = null;
    export let showBackButton = false;

    let content;
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  grid-template-columns: 100vw;
  grid-template-rows: auto 1fr;
  grid-template-areas: "nav" "content";
  overflow: hidden;
  width: 100vw;
  height: 100vh; }
  .container .nav {
    grid-area: nav; }
  .container .content {
    grid-area: content; }

.nav {
  grid-area: nav;
  margin: 96px 96px 0 96px; }

.content {
  overflow: hidden; }
  .content.scroll {
    overflow-x: scroll;
    overflow-y: hidden; }

/*# sourceMappingURL=index.svelte.css.map */</style>

{#if backgroundImage}
    <Background imageUrl={backgroundImage} />
{/if}
<div class="cover container">
    <div class="nav">
        <Nav
            {onBack}
            {showBackButton}
            {nav}
            {title}
            {ariaTopKey}
            {topKey}
            {activeView} />
    </div>
    <div
        class="content"
        class:scroll={!!scrollParentKey}
        bind:this={refs[scrollParentKey || 'container-grid-scroll']}>
        <slot />
    </div>
</div>

<Error />
