<script>
    import { initRefs } from "../../helpers";
    import HorizontalGallery from "../../components/HorizontalGallery";
    import Container from "../../components/container";
    import DataLoader from "../../components/DataLoader";
    import Banner from "../../components/Banner";

    export let nav;
    export let accessToken;

    let refs = initRefs();
</script>

<Container
    {nav}
    showBackButton
    title="All Workouts"
    topKey="gallery.0"
    backgroundImage="https://images.fitplanapp.com:443/tr:w-1200/web/hero/mike-desktop-banner.png">
    <DataLoader
        path="/v1/television/content?includeAthletes=false&includeWorkouts=true&includePlans=false&includeFeatured=false"
        getData={data => data.workouts}
        {accessToken}
        cacheKey="all-workouts">
        <div slot="data" let:data>
            <HorizontalGallery
                entries={data
                    .slice(0, 56)
                    .map(workout => ({
                        url: `/workout/${workout.id}`,
                        title: workout.name,
                        imageUrl: `${workout.imageUrl}?tr=w-500`,
                    }))}
                {nav} />
            <Banner />
        </div>
    </DataLoader>
</Container>
