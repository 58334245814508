<script>
    import { onMount } from "svelte";

    import {
        getKeyUpHandler,
        getKeyDownHandler,
        sleep,
        initAria,
        getRefs,
    } from "../../helpers";

    const refs = getRefs();
    export let refKey;
    export let up;
    export let down;
    export let left;
    export let right;
    export let autofocus;
    export let active;

    export let title;

    if (autofocus) {
        onMount(async () => {
            await sleep(100);
            refs[refKey].focus();
        });
    }
    const { label, labelledBy, asLabel, asHidden } = initAria();
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.container {
  background: none;
  outline: none;
  border: none;
  box-shadow: none;
  font-family: Lato, sans-serif;
  font-size: 30px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #4b4b4b;
  display: inline-block;
  position: relative;
  height: 100%;
  margin-right: 20px; }
  .container:hover, .container:focus, .container:active {
    background: none;
    outline: none;
    border: none;
    box-shadow: none; }
  .container.active {
    color: #fff; }
  .container:focus .bar {
    width: 25%; }
  .container .bar {
    transition: width 0.2s ease;
    width: 0;
    height: 4px;
    border-radius: 2px;
    background-color: #12b587;
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%); }

/*# sourceMappingURL=Tab.svelte.css.map */</style>

<button
    class="container"
    aria-label={$label}
    aria-labelledby={$labelledBy}
    class:active
    focusable
    on:focusin
    on:focusout
    bind:this={refs[refKey]}
    on:keyup={event => getKeyUpHandler(refs, { up, down, left, right })(event)}
    on:keydown={event => getKeyDownHandler(refs, {
            up,
            down,
            left,
            right,
        })(event)}>
    <span use:asLabel>{title}</span>
    <div class="bar">&nbsp;</div>
</button>
