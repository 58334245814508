<script>
    import { getRefs } from "../../helpers";
    import Button from "./Button";
    import BackIcon from "./icons/back";

    const refs = getRefs();
    export let up;
    export let right;
    export let left;
    export let down;
    export let ariaDown;
    export let refKey = "nav.back";
</script>

<style lang="scss">
/*# sourceMappingURL=BackButton.svelte.css.map */</style>

<Button
    on:click
    {refKey}
    {up}
    {ariaDown}
    {down}
    {left}
    {right}
    let:asHidden
    isBackButon={true}>
    <slot>
        <div class="hidden-intro" use:asHidden>
            Go back to the previous page.
        </div>
        <BackIcon />
    </slot>
</Button>
