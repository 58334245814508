<script>
    import { initRefs, getEntryFromPlan } from "../../helpers";
    import HorizontalGallery from "../../components/HorizontalGallery";
    import Container from "../../components/container";
    import DataLoader from "../../components/DataLoader";
    import Banner from "../../components/Banner";

    export let nav;
    export let accessToken;
    export let athleteId;

    let refs = initRefs();
    let athleteName = "";
</script>

<Container
    {nav}
    showBackButton
    title={athleteName ? `${athleteName}'s Plans` : ''}
    topKey="gallery.0"
    backgroundImage="https://images.fitplanapp.com:443/tr:w-1200/web/hero/mike-desktop-banner.png">
    <DataLoader
        path={`/v1/television/athlete/${athleteId}`}
        getData={data => data}
        {accessToken}
        on:loaded={athlete => {
            const { firstName, lastName } = athlete.detail;
            athleteName = `${firstName}${firstName && lastName ? ' ' : ''}${lastName}`;
        }}
        cacheKey={`athlete-${athleteId}-plans`}>
        <div slot="data" let:data>
            <HorizontalGallery
                entries={data.plans.map(getEntryFromPlan)}
                {nav} />
            <Banner />
        </div>
    </DataLoader>
</Container>
