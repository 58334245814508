<script>
    import { onMount } from "svelte";
    import { slide } from "svelte/transition";

    import { sleep, getRefs } from "../../helpers";
    import Button from "../grid/Button";
    import Content from "../grid/Content";
    import MoreButton from "../grid/MoreButton";

    export let entries;
    export let autofocus = false;
    export let onNavigate = url => {};
    const refs = getRefs();
    export let refBase;
    export let down = "";
    export let up = "";

    let focusedIndex = 0;
    let gridItems = entries;
    let index = 20;
    let focused = false;
    let loadingTimeout;

    onMount(async () => {
        refs[refBase] = {
            focus: () => {
                const el = refs[`${refBase}.${focusedIndex}`];
                if (el && el.focus) {
                    el.focus();
                } else {
                    refs[`${refBase}.0`] && refs[`${refBase}.0`].focus();
                }
            },
        };
        await sleep(100);
        if (autofocus) {
            const el = refs[autofocus];
            if (el && el.focus) {
                el.focus();
            } else {
                refs[`${refBase}.0`] && refs[`${refBase}.0`].focus();
            }
        }
    });
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.container {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 24px; }

.entry {
  display: inline-block;
  height: 300px;
  width: 300px;
  border-radius: 8px;
  font-family: Lato, sans-serif;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #fff; }

/*# sourceMappingURL=index.svelte.css.map */</style>

<div class="container">
    {#each gridItems as entry, index}
        <div
            class="entry"
            class:first={index % 2 === 0}
            class:second={index % 2 === 1}>
            <Button
                on:click={() => onNavigate(entry.url)}
                bind:refs
                refKey={`${refBase}.${index}`}
                left={gridItems[index - 1] ? `${refBase}.${index - 1}` : `${refBase}.${gridItems[gridItems.length - 1]}`}
                right={gridItems[index + 1] ? `${refBase}.${index + 1}` : `${refBase}.0`}
                {up}
                {down}
                on:focus={() => {
                    focused = true;
                    focusedIndex = index;
                }}
                on:blur={() => (focused = false)}>
                <Content {entry} focused={focused && focusedIndex === index} />
            </Button>
        </div>
    {/each}
</div>
