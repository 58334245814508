<script>
    import { get, onMount } from "svelte";

    import { initRefs, initFocus } from "../../helpers";
    import Content from "../../components/Content";
    import Spinner from "../../components/Spinner";
    import DataLoader from "../../components/DataLoader";
    import Grid from "../../components/grid";
    import Container from "../../components/container";
    import Button from "../../components/Button";

    export let nav;
    export let accessToken;

    const onNavigate = url => nav.navigate(url);
    let refs = initRefs();
    let scrollParentKey = "discoverScrollParent";

    let initialIndex = 0;
    let refBase = "plans";
    if (window.history.state && window.history.state["refBase"]) {
        refBase = window.history.state["refBase"];
        const refIndex = window.history.state["refIndex"];
        if (typeof refIndex === "number" || refIndex === "more") {
            initialIndex = refIndex;
        }
    }

    initFocus(refs, "nav");
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.container {
  padding: 0 140px 64px 140px;
  box-sizing: border-box;
  height: 100%;
  width: auto; }

.grid {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: repeat(3, auto);
  height: 100%;
  padding-right: 96px; }

.title {
  grid-row: 1;
  font-family: Lato, sans-serif;
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #fff;
  line-height: 1.05;
  color: #ffffff;
  padding: 24px 0;
  margin-right: 96px; }

.list {
  grid-row: 0;
  margin-right: 96px; }

/*# sourceMappingURL=index.svelte.css.map */</style>

<Container
    {nav}
    title="My Fitplan"
    topKey="plans.0"
    activeView="discover"
    {scrollParentKey}
    backgroundImage="https://images.fitplanapp.com:443/tr:w-1920/web/hero/mike-desktop-banner.png">
    <DataLoader
        path="/v1/television/content?includeAthletes=true&includeWorkouts=true&includePlans=true&countPerSection=5"
        {accessToken}
        cacheKey="discover">
        <div slot="data" let:data>
            <div class="container">
                <div class="grid">
                    <div class="title">Plans</div>
                    <div class="list">
                        <Grid
                            entries={data.plans ? data.plans.map(plan => ({
                                      url: `/plan/${plan.id}`,
                                      title: plan.name,
                                      subtitle: `By ${plan.athleteFirstName} ${plan.athleteLastName}`,
                                      imageUrl: `${plan.imageSmallUrl}?tr=w-500`,
                                  })) : []}
                            {scrollParentKey}
                            moreUrl="/plans"
                            moreText="More Fitplans"
                            {onNavigate}
                            refBase={'plans'}
                            autofocus={refBase === 'plans' ? `plans.${initialIndex}` : false}
                            up={'nav'}
                            nextBase="athletes"
                            prevBase="workouts" />
                    </div>
                    <div class="title">Elite Trainers</div>
                    <div class="list">
                        <Grid
                            entries={data.athletes ? data.athletes.map(
                                      athlete => ({
                                          url: `/athlete/${athlete.id}`,
                                          title: `${athlete.firstName} ${athlete.lastName}`,
                                          subtitle: athlete.shortDescription,
                                          imageUrl: `${athlete.imageTv}?tr=w-500`,
                                      })
                                  ) : []}
                            {scrollParentKey}
                            moreUrl="/athletes"
                            moreText="More Elite Trainers"
                            {onNavigate}
                            refBase={'athletes'}
                            autofocus={refBase === 'athletes' ? `athletes.${initialIndex}` : false}
                            up={'nav'}
                            prevBase="plans"
                            nextBase="workouts" />
                    </div>
                    <div class="title">Workouts</div>
                    <div class="list">
                        <Grid
                            entries={data.workouts ? data.workouts.map(
                                      workout => ({
                                          url: `/workout/${workout.id}`,
                                          title: workout.name,
                                          imageUrl: `${workout.imageUrl}?tr=w-500`,
                                      })
                                  ) : []}
                            {scrollParentKey}
                            moreUrl="/workouts"
                            moreText="More Workouts"
                            {onNavigate}
                            refBase={'workouts'}
                            autofocus={refBase === 'workouts' ? `workouts.${initialIndex}` : false}
                            up={'nav'}
                            nextBase="plans"
                            prevBase="athletes" />
                    </div>
                </div>
            </div>
        </div>
    </DataLoader>
</Container>
