<script>
    import { onMount, tick } from "svelte";

    import { sleep, getRefs, setScrollParent } from "../../helpers";
    import Content from "./Content";
    import Button from "./Button";
    import Info from "./Info";

    export let nav;
    export let entries;
    export let refBase = "";
    export let up = "";
    export let down = "";
    export let onStart = () => {};
    export let historyKey = "none";

    const scrollParentKey = "carouselScrollParent";
    const scrollParent = setScrollParent(scrollParentKey);
    const refs = getRefs();

    let focusedIndex = 0;
    let focused = false;
    let nextIndex = 0;
    let timeout = null;

    const setZIndex = (index, zIndex) => {
        const self = refs[`${refBase}.${index}.parent`];
        self.style.zIndex = `${zIndex}`;
    };

    const scroll = (index, smooth = true) => {
        const parent = refs[scrollParentKey];
        parent.scrollTo({
            left: index * 424,
            behavior: smooth ? "smooth" : undefined,
        });
    };

    let initialIndex = -10;
    if (historyKey && window.history.state) {
        if (typeof window.history.state[historyKey] === "number") {
            initialIndex = window.history.state[historyKey];
        }
    }
    if (initialIndex < 0) {
        initialIndex = 0;
        while (entries[initialIndex] && entries[initialIndex].completed) {
            initialIndex += 1;
        }
    }
    nextIndex = focusedIndex = initialIndex;

    onMount(async () => {
        refs[refBase] = {
            focus: async () => {
                if (nextIndex !== 0) {
                    setZIndex(0, 500);
                }
                setZIndex(nextIndex, 1000);
                scroll(nextIndex);
                await sleep(50);
                refs[`${refBase}.${nextIndex}`].focus();
            },
        };
        await tick();

        setZIndex(initialIndex, initialIndex === 0 ? 500 : 1000);
        scroll(initialIndex, false);
    });

    const isHidden = (index, focusedIndex, nextIndex, entries) => {
        const len = entries.length;
        if (focusedIndex - 5 <= index && focusedIndex + 5 >= index) {
            return false;
        }
        if (index > focusedIndex && index < nextIndex + 5) {
            return false;
        }
        return true;
    };

    let frame;
    const updateIndex = async () => {
        if (nextIndex === focusedIndex) return;
        if (frame) cancelAnimationFrame(frame);
        focused = false;
        await tick();
        frame = requestAnimationFrame(() => {
            scroll(nextIndex);
            frame = requestAnimationFrame(() => {
                setZIndex(focusedIndex, focusedIndex === 0 ? 500 : 100);
                frame = requestAnimationFrame(() => {
                    setZIndex(nextIndex, 1000);
                    frame = requestAnimationFrame(() => {
                        refs[`${refBase}.${nextIndex}`].focus();
                    });
                });
            });
        });
    };
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.container {
  overflow-x: scroll;
  overflow-y: visible;
  width: 100vw;
  height: 100%; }
  .container::-webkit-scrollbar {
    display: none; }

.content {
  display: inline-block;
  width: 100%;
  height: 100%;
  padding-right: 768px; }

.flexbox {
  padding-top: 156px;
  padding-bottom: 400px;
  padding-left: 748px;
  padding-right: 748px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap; }
  .flexbox .entry:first-child {
    z-index: 500; }

.entry {
  flex: 0 0 400px;
  margin: 0 12px;
  height: 400px;
  position: relative;
  z-index: 100;
  transition: all 0.2s ease; }
  .entry:first {
    z-index: 500; }
  .entry.focused {
    -webkit-transform: scale(1.3) translateY(-40px); }

/*# sourceMappingURL=index.svelte.css.map */</style>

<div class="container" use:scrollParent>
    <span class="content">
        <div class="flexbox">
            {#each entries as entry, index}
                <div
                    class="entry"
                    class:focused={focused && focusedIndex === index}
                    bind:this={refs[`${refBase}.${index}.parent`]}
                    on:transitionend={async event => {
                        if (event.propertyName === 'transform' && nextIndex !== index) {
                            scroll(nextIndex);
                        }
                    }}>
                    {#if !isHidden(index, focusedIndex, nextIndex, entries)}
                        <Button
                            on:click={() => onStart(entry)}
                            refKey={`${refBase}.${index}`}
                            on:mouseup={() => setTimeout(() => {
                                    if (refs[`${refBase}.${index}.parent`]) {
                                        refs[`${refBase}.${index}.parent`].style.transform = '';
                                    }
                                }, 250)}
                            on:mousedown={() => {
                                refs[`${refBase}.${index}.parent`].style.transform = 'scale(1.25) translateY(-40px)';
                            }}
                            on:focus={async () => {
                                focusedIndex = index;
                                focused = true;
                                if (historyKey) {
                                    window.history.replaceState({ ...(window.history.state || {}), [historyKey]: index }, '');
                                }
                            }}
                            on:blur={() => {
                                focused = false;
                            }}
                            on:left={async () => {
                                nextIndex = index === 0 ? index : index - 1;
                                await updateIndex();
                            }}
                            on:right={async () => {
                                nextIndex = index + 1 === entries.length ? index : index + 1;
                                await updateIndex();
                            }}
                            on:up={() => {
                                refs[up] && refs[up].focus();
                            }}
                            let:focused={isFocused}
                            let:asLabel
                            let:asHidden>
                            <span use:asHidden={0}>{entry.subtitle}</span>
                            <span use:asHidden={1}>{entry.title}</span>
                            <span use:asHidden={2}>{entry.first}</span>
                            <span use:asHidden={3}>{entry.second}</span>
                            {#if entry.mobileOnly}
                                <span use:asHidden={20}>Mobile Only</span>
                            {/if}
                            {#if entry.completed}
                                <span use:asHidden={10}>Completed.</span>
                            {/if}
                            <Content {entry} focused={isFocused} />
                        </Button>
                        <Info
                            {entry}
                            focused={focused && focusedIndex === index} />
                    {/if}
                </div>
            {/each}
            <div class="entry">&nbsp;</div>
            <div class="entry">&nbsp;</div>
            <div class="entry">&nbsp;</div>
        </div>
    </span>
</div>
