<script>
    import { onMount, tick } from "svelte";
    import { slide } from "svelte/transition";

    import { sleep, getRefs } from "../../helpers";
    import Button from "./Button";
    import Content from "./Content";
    import MoreButton from "./MoreButton";

    export let entries;
    export let moreUrl = "";
    export let moreText = "";
    export let autofocus = false;
    export let scrollParentKey = "";
    export let onNavigate = url => {};
    const refs = getRefs();
    export let refBase;
    export let nextBase = "";
    export let down = "";
    export let prevBase = "";
    export let up = "";

    let focused = false;
    let focusedIndex = [];

    onMount(async () => {
        const updateRefs = () => {
            refs[`${refBase}.top`] = refs[`${refBase}.${entries.length - 1}`];
            refs[`${refBase}.bottom`] =
                refs[`${refBase}.${entries.length - 1}`];
            if (!moreUrl) {
                refs[`${refBase}.more`] =
                    refs[
                        `${refBase}.${
                            entries.length % 2 === 1
                                ? entries.length - 2
                                : entries.length - 1
                        }`
                    ];
            }
        };

        await sleep(100);
        updateRefs();
        if (autofocus) {
            const el = refs[autofocus];
            if (el && el.focus) {
                el.focus();
            } else {
                refs[`${refBase}.0`] && refs[`${refBase}.0`].focus();
            }
        }
    });
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.container {
  display: grid;
  grid-template-rows: auto auto;
  grid-gap: 24px; }

.entry {
  display: inline-block;
  height: 300px;
  width: 300px;
  border-radius: 8px;
  font-family: Lato, sans-serif;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #fff; }
  .entry.hidden {
    visibility: hidden; }

.first {
  grid-row: 1; }

.second {
  grid-row: 0; }

/*# sourceMappingURL=index.svelte.css.map */</style>

<div class="container">
    {#each entries as entry, index}
        <div
            class="entry"
            class:first={index % 2 === 0}
            class:second={index % 2 === 1}>
            <Button
                on:click={() => onNavigate(entry.url)}
                bind:refs
                {scrollParentKey}
                refKey={`${refBase}.${index}`}
                left={entries[index - 2] ? `${refBase}.${index - 2}` : `${prevBase}.${index % 2 === 0 ? 'top' : 'more'}`}
                right={entries[index + 2] ? `${refBase}.${index + 2}` : index % 2 === 1 ? `${refBase}.more` : `${nextBase}.0`}
                up={index % 2 === 0 ? up : `${refBase}.${index - 1}`}
                down={index % 2 === 1 ? down : entries[index + 1] ? `${refBase}.${index + 1}` : `${refBase}.more`}
                on:focus={() => {
                    window.history.replaceState({ ...(window.history.state || {}), refBase, refIndex: index }, '');
                    focusedIndex = index;
                    focused = true;
                }}
                on:blur={() => (focused = false)}>
                <Content {entry} focused={focused && focusedIndex === index} />
            </Button>
        </div>
    {/each}

    {#if moreUrl || entries.length === 1}
        <div class="entry second" class:hidden={!moreUrl}>
            <Button
                on:click={() => onNavigate(moreUrl)}
                bind:refs
                {scrollParentKey}
                refKey={`${refBase}.more`}
                left={`${refBase}.${entries.length - 2}`}
                right={`${nextBase}.1`}
                up={`${refBase}.${entries.length - 1}`}
                {down}
                on:focus={() => {
                    focused = true;
                    focusedIndex = 'more';
                    window.history.replaceState({ ...(window.history.state || {}), refBase, refIndex: 'more' }, '');
                }}
                on:blur={() => (focused = false)}>
                <MoreButton
                    focused={focused && focusedIndex === 'more'}
                    text={moreText} />
            </Button>
        </div>
    {/if}
</div>
