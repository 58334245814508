<script>
    import VideoContainer from "../../components/container/VideoContainer";
    import PreviewVideo from "../../components/PreviewVideo";
    import Button from "../../components/Button";
    import DataLoader from "../../components/DataLoader";
    import InfoBlock from "../../components/InfoBlock";
    import {
        getInfoStreamFromWorkout,
        getInfoImageFromWorkout,
        initRefs,
    } from "../../helpers";

    export let nav;
    export let accessToken;
    export let workoutId;

    let refs = initRefs();

    let loading = true;
    let videoUrl;
    let previewUrl;
    let scrollParent;
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.title {
  font-family: Lato, sans-serif;
  font-size: 64px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #fff;
  color: #f5f5f5;
  margin-bottom: 16px; }

.subtitle {
  font-family: Lato, sans-serif;
  font-size: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #fff;
  font-weight: 500;
  color: #d9d9d9;
  margin-bottom: 56px; }

.video {
  position: relative;
  width: 100%;
  height: 100%; }

.button-container {
  position: relative;
  height: 96px;
  margin-bottom: 32px; }
  .button-container .button {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%); }

/*# sourceMappingURL=info.svelte.css.map */</style>

<VideoContainer
    {nav}
    topKey="info"
    backgroundImage="https://images.fitplanapp.com:443/tr:w-1920/web/hero/mike-desktop-banner.png"
    {loading}>
    <div slot="info">
        <DataLoader
            path={`/v1/television/workouts/details?workoutId=${workoutId}`}
            {accessToken}
            cacheKey={`workout-${workoutId}`}
            on:loaded={workout => {
                loading = false;
                videoUrl = getInfoStreamFromWorkout(workout.detail);
                previewUrl = getInfoImageFromWorkout(workout.detail);
            }}
            on:error={() => (loading = false)}>
            <div slot="data" let:data>
                <div class="info">
                    <div class="title">{data.name}</div>
                    <div class="subtitle">
                        {data.athleteFirstName} {data.athleteLastName}
                    </div>
                    <InfoBlock
                        down="start"
                        up="nav.back"
                        refKey="info"
                        text={data.description} />
                    <div class="button-container">
                        <div class="button">
                            <Button
                                autofocus
                                refKey="start"
                                on:click={() => {
                                    if (data.mobileOnly === true || data.available === false) {
                                        nav.navigate(`/settings/link?link=workout-${workoutId}`);
                                        return;
                                    }
                                    nav.navigate(`/workout/${workoutId}/video`);
                                }}
                                up="info"
                                down="plan"
                                right="play">
                                Start Workout
                            </Button>
                        </div>
                    </div>

                    <div class="button-container">
                        <div class="button">
                            <Button
                                refKey="plan"
                                on:click={() => nav.navigate(`/plan/${data.planId}`)}
                                up="start"
                                right="play">
                                View Plan
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="loading" />
        </DataLoader>
    </div>
    <div class="video" slot="video">
        {#if !loading}
            <PreviewVideo {nav} {videoUrl} {previewUrl} left="start" />
        {/if}
    </div>
</VideoContainer>
