<script>
    import { onMount } from "svelte";
    import {
        focusRef,
        isInViewport,
        sleep,
        getKeyUpHandler,
        getKeyDownHandler,
        getRefs,
        initAria,
    } from "../../helpers";

    const refs = getRefs();
    export let refKey;
    export let autofocus = false;
    export let className = "";
    export let left = "";
    export let right = "";
    export let up = "";
    export let down = "";
    export let ariaLeft = "";
    export let ariaRight = "";
    export let ariaUp = "";
    export let ariaDown = "";
    export let active = false;
    export let isBackButton = false;

    onMount(async () => {
        if (autofocus) {
            await sleep(100);
            refs[refKey] && refs[refKey].focus();
        }
    });
    const { label, labelledBy, asLabel, asHidden } = initAria();
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.button {
  width: 100px;
  height: 71px;
  border-radius: 47.5px;
  background-image: linear-gradient(55deg, #12b587, #29db57 100%);
  position: relative;
  padding: 0;
  margin: 0;
  position: relative;
  object-fit: cover;
  background: none;
  border: none;
  transition: all 0.1s ease-in-out;
  stroke: #595959;
  fill: #595959;
  margin-right: 8px; }
  .button:focus, .button.active {
    stroke: #fff;
    fill: #fff;
    -webkit-transform: scale(1.05); }
  .button.active .bg {
    background-color: rgba(68, 68, 68, 0.5);
    opacity: 1; }
  .button:focus .bg {
    background-image: linear-gradient(55deg, #12b587, #29db57 100%);
    opacity: 1; }
  .button:focus .shadow {
    opacity: 0.7; }
  .button.back-button {
    width: 88px;
    height: 88px;
    border-radius: 44px; }
    .button.back-button .shadow {
      width: 72px;
      height: 72px;
      border-radius: 44px;
      filter: blur(15px);
      background-image: linear-gradient(45deg, #12b587, #29db57); }

.bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  border-radius: 47.5px;
  background-color: rgba(68, 68, 68, 0.5);
  transition: opacity 0.2s ease-in-out; }

.shadow {
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
  opacity: 0;
  width: 84px;
  height: 59px;
  border-radius: 47.5px;
  filter: blur(15px);
  background-image: linear-gradient(55deg, #12b587, #29db57 100%);
  transition: opacity 0.2s ease-in-out; }

.contents {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%); }

/*# sourceMappingURL=Button.svelte.css.map */</style>

<button
    class={`button ${className}`}
    aria-label={$label}
    aria-labelledby={$labelledBy}
    class:active
    class:back-button={isBackButton}
    on:click
    on:keyup={event => {
        if (event.key === 'Enter') {
            refs[refKey].style.transform = '';
        }
        getKeyUpHandler(refs, {
            up,
            down,
            left,
            right,
            ariaUp,
            ariaDown,
            ariaLeft,
            ariaRight,
        })(event);
    }}
    on:keydown={event => {
        if (event.key === 'Enter') {
            refs[refKey].style.transform = 'scale(1)';
        }
        getKeyDownHandler(refs, {
            up,
            down,
            left,
            right,
            ariaUp,
            ariaDown,
            ariaLeft,
            ariaRight,
        })(event);
    }}
    on:focusin
    on:focusout
    bind:this={refs[refKey]}>
    <div class="bg" />
    <div class="shadow" />
    <div class="contents">
        <slot {asLabel} {asHidden} />
    </div>
</button>
