<script>
    export let nav;
</script>

<style>
    .container {
        display: grid;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        grid-template-areas:
            ". . ."
            ". content ."
            ". . .";
        grid-template-rows: 1fr auto 1fr;
        grid-template-columns: 1fr auto 1fr;

        scroll-behavior: smooth;
    }

    .content {
        grid-area: content;
    }
</style>

<div class="container">
    <div class="content">
        <slot />
    </div>
</div>