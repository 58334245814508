<script>
    import { onMount } from "svelte";

    import {
        initRefs,
        setScrollParent,
        getCarouselEntryFromWorkout,
    } from "../../helpers";
    import Carousel from "../../components/carousel";
    import Container from "../../components/container";
    import DataLoader from "../../components/DataLoader";

    export let nav;
    export let accessToken;
    export let planId;

    let refs = initRefs();
    const scrollParent = setScrollParent();
    let planName;

    let initialIndex =
        (window.history.state && window.history.state.workoutId) || 0;
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.container {
  overflow: hidden; }

/*# sourceMappingURL=workouts.svelte.css.map */</style>

<Container
    {nav}
    showBackButton
    title={planName ? planName : ''}
    topKey="workouts"
    backgroundImage="https://images.fitplanapp.com:443/tr:w-1200/web/hero/mike-desktop-banner.png">
    <DataLoader
        path={`/v1/television/plan/${planId}`}
        getData={data => data}
        {accessToken}
        on:loaded={plan => {
            const { name } = plan.detail;
            planName = name;
        }}
        cacheKey={`plan-${planId}-details`}>
        <div class="container" slot="data" let:data>
            <Carousel
                entries={data.basicWorkouts.map(getCarouselEntryFromWorkout)}
                onStart={workout => {
                    if (workout.mobileOnly === true || workout.available === false) {
                        nav.navigate(`/settings/link?link=workout-${workout.id}`);
                        return;
                    }
                    nav.navigate(`/workout/${workout.id}`);
                }}
                historyKey="workoutId"
                up="nav.back"
                refBase="workouts"
                {nav} />
        </div>
    </DataLoader>
    <span use:scrollParent />
</Container>
