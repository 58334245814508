<script>
    import PlayIcon from "../icons/Play";
    import CompletedIcon from "../icons/CompletedIcon";
    import Image from "../Image";

    export let entry;
    export let focused;
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 24px;
  opacity: 0;
  transition: opacity 0.1s ease;
  box-shadow: 0 64px 96px 0 rgba(0, 0, 0, 0.8);
  background-color: #262626;
  overflow: hidden; }
  .container.focused {
    opacity: 1; }

.inner {
  width: 100%;
  height: 100%;
  min-height: 560px;
  position: relative; }

.img-container {
  overflow: hidden;
  border-radius: 24px 24px 0 0; }
  .img-container.completed {
    opacity: 0.7; }
  .img-container :global(.carousel-info-lazy-image) {
    height: 400px;
    object-fit: cover;
    object-position: center; }

.info {
  margin: 16px 24px 24px 24px;
  font-family: Lato, sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #fff;
  color: #e8e8e8; }
  .info .title {
    font-weight: bold;
    font-size: 30px;
    line-height: 1.2;
    color: white;
    margin-bottom: 36px; }
  .info .detail {
    font-weight: 500;
    line-height: 1;
    margin-top: 8px;
    color: #8c8c8c; }

.start {
  position: absolute;
  right: 16px;
  bottom: 16px;
  fill: #fff;
  width: 80px;
  height: 80px;
  border-radius: 40px;
  background-image: linear-gradient(55deg, #12b587, #29db57 100%);
  z-index: 2000; }
  .start .start-inner {
    width: 100%;
    height: 100%;
    position: relative; }
    .start .start-inner .start-icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(calc(-50% + 4px), calc(-50% + 2px));
      z-index: 2100; }

.completed-icon {
  position: absolute;
  top: 24px;
  left: 24px; }

.completed-text {
  position: absolute;
  top: 24px;
  left: 72px;
  font-family: Lato, sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #fff;
  opacity: 0;
  transition: opacity 0.2s ease;
  color: white; }
  .completed-text.active {
    opacity: 1; }

.mobile-only {
  position: absolute;
  right: 20px;
  top: 20px;
  height: 28px;
  border-radius: 4px;
  background-color: #fed600;
  padding: 4px 8px 0 8px;
  font-family: Lato, sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #fff;
  color: #262626; }

/*# sourceMappingURL=Info.svelte.css.map */</style>

<div class="container" class:focused>
    <div class="inner">
        <div class="img-container" class:completed={entry.completed}>
            <Image
                className="carousel-info-lazy-image"
                showSpinner
                src={entry.imageUrl}
                alt={entry.title} />
        </div>
        <div class="info">
            <div class="subtitle">{entry.subtitle}</div>
            <div class="title">{entry.title}</div>
            <div class="detail">{entry.first}</div>
            <div class="detail">{entry.second}</div>
        </div>
        <div class="start">
            <div class="start-inner">
                <div class="start-icon">
                    <PlayIcon />
                </div>
            </div>
        </div>
        {#if entry.completed}
            <div class="completed-icon">
                <CompletedIcon />
            </div>
            <div class="completed-text" class:active={focused}>Completed</div>
        {/if}
        {#if entry.mobileOnly}
            <div class="mobile-only">Mobile Only</div>
        {/if}
    </div>
</div>
