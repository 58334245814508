<script>
    export let width = 52;
    export let height = 63;
</script>

<svg
    xmlns="http://www.w3.org/2000/svg"
    width={`${width}`}
    height={`${height}`}
    viewBox="0 0 52 63">
    <g fill="none" fill-rule="evenodd">
        <path
            fill="#FFF"
            fill-rule="nonzero"
            d="M5 20v10.75C5 36.301 9.31 41.5 16.13 41.5a10.705 10.705 0 007.119
            7.507c-.48 2.519-1.366 5.673-3.018
            8.618H15.75V63h21.5v-5.375h-4.48c-1.653-2.945-2.54-6.099-3.019-8.618A10.705
            10.705 0 0036.87 41.5C43.668 41.5 48 36.318 48 30.75V20H5zm5.375
            10.75v-5.375h5.375v10.75a5.381 5.381 0 01-5.375-5.375zm32.25 0a5.381
            5.381 0 01-5.375 5.375v-10.75h5.375v5.375z"
            transform="translate(-1)" />
        <g fill="#95979A">
            <path
                d="M46.929 7.745L50.468 7.806 50.619 16.685 47.081 16.624z"
                transform="translate(-1) rotate(42 48.774 12.215)" />
            <path
                d="M3.381 7.745L6.919 7.806 7.071 16.685 3.532 16.624z"
                transform="translate(-1) scale(-1 1) rotate(42 0 -1.399)" />
            <path
                d="M34.887 0.549L38.395 0.501 38.274 9.457 34.767 9.505z"
                transform="translate(-1) scale(-1 1) rotate(-26 0 163.451)" />
            <path
                d="M15.726 0.549L19.233 0.501 19.113 9.457 15.605 9.505z"
                transform="translate(-1) rotate(-26 17.42 5.003)" />
        </g>
    </g>
</svg>
