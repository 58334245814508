<script>
    import { initRefs, getEntryFromPlan } from "../../helpers";
    import HorizontalGallery from "../../components/HorizontalGallery";
    import Container from "../../components/container";
    import DataLoader from "../../components/DataLoader";
    import Banner from "../../components/Banner";

    export let nav;
    export let accessToken;

    let refs = initRefs();
</script>

<Container
    {nav}
    showBackButton
    title="All Fitplans"
    topKey="gallery.0"
    backgroundImage="https://images.fitplanapp.com:443/tr:w-1200/web/hero/mike-desktop-banner.png">
    <DataLoader
        path="/v1/television/plans"
        getData={data => data.filter(plan => plan.available)}
        {accessToken}
        cacheKey="all-plans">
        <div slot="data" let:data>
            <HorizontalGallery entries={data.map(getEntryFromPlan)} {nav} />
            <Banner />
        </div>
    </DataLoader>
</Container>
