<script>
    import { get, onMount } from "svelte";

    import { getRefs } from "../helpers";
    import Content from "../components/Content";
    import Spinner from "../components/Spinner";
    import Grid from "../components/grid";
    import Button from "../components/Button";

    export let nav;
    const refs = getRefs();
    export let entries;

    const scrollParentKey = "gridScrollParent";

    const onNavigate = url => nav.navigate(url);

    let initialIndex = 0;
    if (window.history.state) {
        const refIndex = window.history.state["refIndex"];
        if (typeof refIndex === "number" || refIndex === "more") {
            initialIndex = refIndex;
        }
    }
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.container {
  padding: 0 96px 48px 96px;
  overflow-x: scroll;
  box-sizing: border-box;
  height: 100%;
  width: auto; }
  .container::-webkit-scrollbar {
    display: none; }

.grid {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(auto-fit, auto);
  justify-content: start;
  height: 100%;
  padding-top: 48px; }

.list {
  grid-row: 0;
  padding-right: 96px; }

/*# sourceMappingURL=HorizontalGallery.svelte.css.map */</style>

<div class="container" bind:this={refs[scrollParentKey]}>
    <div class="grid">
        <div class="list">
            <Grid
                {entries}
                autofocus={`gallery.${initialIndex}`}
                {onNavigate}
                {scrollParentKey}
                refBase={'gallery'}
                up={'nav.back'}
                nextBase="gallery"
                prevBase="gallery" />
        </div>
    </div>
</div>
