<script>
    import { createEventDispatcher } from "svelte";

    import { isInViewport, getRefs, getScrollParent } from "../helpers";
    import Spinner from "./Spinner";

    export let src;
    export let alt;
    export let className = "";
    export let lazy = false;
    export let preview = "";
    export let showSpinner;

    const dispatch = createEventDispatcher();
    const loaded = new Map();

    const refs = getRefs();

    let visible = false;

    const lazyLoad = (node, data) => {
        const loadImage = () => {
            if (loaded.has(data.src)) {
                node.setAttribute("src", data.src);
            } else {
                const img = new Image();
                img.src = data.src;
                img.onload = () => {
                    loaded.set(data.src, img);
                    node.setAttribute("src", data.src);
                    dispatch("loaded");
                    console.log("Loaded image ", alt, " => ", data.src);
                    visible = true;
                };
            }
        };
        const scrollParent = getScrollParent();
        if (lazy) {
            const { x, width } = node.getBoundingClientRect();
            if (x + width + 30 < 0 || x - 30 > 1920) {
                let onScroll = () => {
                    const { x } = node.getBoundingClientRect();
                    let diff = 0 - x < 0 ? x - 1920 : 0 - x;
                    if (diff <= 330) {
                        loadImage();
                        if (onScroll) {
                            scrollParent &&
                                scrollParent.removeEventListener(
                                    "scroll",
                                    onScroll
                                );
                            window.removeEventListener(
                                "CustomScroll",
                                onScroll
                            );
                            onScroll = null;
                        }
                    }
                };
                scrollParent &&
                    scrollParent.addEventListener("scroll", onScroll);
                window.addEventListener("CustomScroll", onScroll);
                return {
                    destroy: () => {
                        if (onScroll) {
                            scrollParent &&
                                scrollParent.removeEventListener(
                                    "scroll",
                                    onScroll
                                );
                            window.removeEventListener(
                                "CustomScroll",
                                onScroll
                            );
                            onScroll = null;
                        }
                    },
                };
            }
        }
        loadImage();

        return {
            destroy: () => {}, // noop
        };
    };
</script>

<style lang="scss">img {
  object-fit: cover;
  object-position: center;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease-in-out; }
  img.visible {
    opacity: 1; }

/*# sourceMappingURL=Image.svelte.css.map */</style>

<img
    {alt}
    src={src || preview}
    class:visible
    use:lazyLoad={{ src }}
    class={className} />

{#if showSpinner && !visible}
    <Spinner display="grid" />
{/if}
