<script>
    import { onMount, tick } from "svelte";
    import { captureException } from "@sentry/browser";

    import { getLoginCode } from "../../api";
    import { apiBaseUrl, websiteBaseUrl } from "../../config";
    import Spinner from "../../components/Spinner";
    import Container from "../../components/container";
    import Input from "../../components/Input";
    import VoiceHelper from "../../components/VoiceHelper";
    import qrcode from "qrcode";
    import {
        sleep,
        login,
        initRefs,
        initFocus,
        setCachedValue,
        clearCache,
        setError,
        setCurrentError,
        setVoiceIntro,
        replaceLocation,
    } from "../../helpers";

    import Logo from "./logo.png";

    export let nav;

    let code;
    let canvas;
    let loading = true;
    let loggedIn = false;
    let connection = null;
    const refs = initRefs();
    const deviceId = window.webapis.productinfo.getDuid();

    const onBack = () => replaceLocation(nav, "/splash");

    const closeConnection = () => {
        if (connection) {
            try {
                connection.close();
            } catch (err) {}
            connection = null;
        }
    };

    const createConnection = () => {
        closeConnection();
        if (loggedIn) {
            return;
        }
        connection = new EventSource(
            `${apiBaseUrl}/v1/television/tizen/login?code=${code}&deviceId=${deviceId}`
        );
        connection.onmessage = onMessage;
        connection.onerror = onError;
    };

    const onMessage = event => {
        let accessToken;
        try {
            const { access_token } = JSON.parse(event.data);
            if (access_token) {
                accessToken = access_token;
            }
        } catch (err) {}
        if (accessToken) {
            loading = true;
            setCachedValue("accessToken", accessToken, 365)
                .then(async () => {
                    loggedIn = true;
                    await tick();
                    replaceLocation(nav, "/");
                })
                .catch(err => setCurrentError(err, refs, "nav.back"));
        } else {
            createConnection();
        }
    };

    const onError = error => {
        if (error.target === connection) {
            createConnection();
        } else {
            setCurrentError(error, refs);
        }
    };

    onMount(async () => {
        try {
            code = await getLoginCode(deviceId);
            loading = false;
            await sleep(100);
            qrcode.toCanvas(
                document.getElementById("qr-code-canvas"),
                `${websiteBaseUrl}/tv?code=${code}`,
                {
                    width: 320,
                    height: 320,
                },
                function(error) {
                    if (error) setCurrentError(error, refs);
                }
            );
            createConnection();
        } catch (err) {
            captureException(err);
            setCurrentError(err, refs);
        }
    });

    initFocus(refs, "voiceCode", 200);
    const asIntro = setVoiceIntro();
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.container {
  display: grid;
  width: 100vw;
  height: 100%;
  grid-template-areas: ". .    . logo . .    ." ". .    . .    . .    ." ". code . top  . info ." ". code . or   . info ." ". code . btm  . info ." ". .    . .    . .    .";
  grid-template-columns: 270px 1fr 200px auto 200px 1fr 270px;
  grid-template-rows: 64px 70px 260px auto 260px 1fr;
  text-align: center;
  align-items: stretch;
  font-family: Lato, sans-serif;
  font-size: 24px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #bfbfbf; }
  .container .logo {
    grid-area: logo; }
  .container .code {
    grid-area: code; }
  .container .top {
    grid-area: top; }
  .container .or {
    grid-area: or; }
  .container .btm {
    grid-area: btm; }
  .container .info {
    grid-area: info; }
  .container .code,
  .container .info {
    padding-top: 64px;
    padding-bottom: 64px; }
    .container .code div,
    .container .info div {
      margin-top: 64px; }
    .container .code canvas,
    .container .info canvas {
      width: 300px;
      height: 300px;
      margin: 48px auto 0 auto; }
  .container .logo img {
    width: 64px; }
  .container .or {
    position: relative;
    width: 100%;
    height: 100%; }
    .container .or div {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%); }
  .container .top,
  .container .btm {
    position: relative;
    width: 100%;
    height: 100%; }
    .container .top div,
    .container .btm div {
      width: 1px;
      background: #bfbfbf;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%); }
  .container h1 {
    font-family: Lato, sans-serif;
    font-size: 40px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #f5f5f5; }
  .container h2 {
    margin-top: 16px;
    font-size: 36px;
    letter-spacing: 2px;
    color: #f5f5f5; }

/*# sourceMappingURL=license.svelte.css.map */</style>

<Container
    {nav}
    topKey="nav"
    ariaTopKey="voiceCode"
    {onBack}
    showBackButton
    backgroundImage="https://images.fitplanapp.com:443/tr:w-1920/web/hero/mike-desktop-banner.png">
    {#if code}
        <p class="hidden-intro" use:asIntro>
            Sign in using your mobile device by scanning the QR Code or by
            opening fitplanapp.com/tv in a web browser and entering the code. {code
                .split('')
                .join('. ')}.
        </p>
        <div class="container">
            <div class="logo">
                <img src={Logo} alt="Fitplan logo" />
            </div>
            <div class="top">
                <div>&nbsp;</div>
            </div>
            <div class="or">
                <div>or</div>
            </div>
            <div class="btm">
                <div>&nbsp;</div>
            </div>
            <div class="code">
                <h1>Scan QR code to sign in</h1>
                <div>
                    To scan, open your camera app and place the code in your
                    screen
                </div>
                <canvas id="qr-code-canvas" />
            </div>
            <div class="info">
                <h1>Visit website to sign in</h1>
                <div>1. Visit website:</div>
                <h2>fitplanapp.com/tv</h2>
                <div>2. When asked, enter code:</div>
                <h2>{code}</h2>
                <VoiceHelper refKey="voiceCode" up="nav">
                    Sign in or register online at fitplanapp.com/tv with the
                    code. {code.split('').join('. ')}.
                </VoiceHelper>
            </div>
        </div>
    {/if}
</Container>
{#if loading}
    <Spinner />
{/if}
