<script>
    import { tick } from "svelte";

    import { currentError, clearCurrentError, getRefs } from "../helpers";
    import Button from "./Button";

    const refs = getRefs();
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.container {
  position: fixed;
  overflow: hidden;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  width: 40%;
  border-radius: 24px;
  box-shadow: 0 5px 15px rgba(17, 18, 19, 0.1), 0 15px 50px rgba(17, 18, 19, 0.25);
  background-color: #111213;
  font-family: Lato, sans-serif;
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #fff;
  line-height: 1.3;
  color: white;
  text-align: center;
  padding: 16px;
  z-index: 10000; }
  .container .icon {
    display: inline-block;
    color: white;
    background-color: red;
    border-radius: 32px;
    width: 64px;
    height: 64px;
    margin-top: 24px; }
  .container .subtitle {
    font-family: Lato, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #fff; }
  .container .text {
    font-family: Lato, sans-serif;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #fff; }
  .container > * {
    margin-bottom: 24px; }

.bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(17, 18, 19, 0.5); }

.bg,
.container {
  opacity: 0; }
  .bg.active,
  .container.active {
    opacity: 1; }

/*# sourceMappingURL=Error.svelte.css.map */</style>

{#if $currentError}
    <div class="bg" class:active={!!$currentError} />
    <div class="container" class:active={!!$currentError}>
        <div class="icon">X</div>
        <div class="subtitle">An Error Occurred</div>
        <div class="text">
            {typeof $currentError.message === 'undefined' ? 'An unknown error occurred. Please try again later' : $currentError.message}
        </div>
        <div class="btn">
            <Button
                refKey="close"
                autofocus
                fixedWidth
                on:click={async () => {
                    const refKey = $currentError.refKey;
                    clearCurrentError();
                    await tick();
                    if (refs[refKey]) {
                        refs[refKey].focus();
                    } else {
                        const el = refs['nav'];
                        if (el) {
                            el.focus();
                        } else {
                            refs['nav.back'] && refs['nav.back'].focus();
                        }
                    }
                }}
                up="start">
                Close
            </Button>
        </div>
    </div>
{/if}
