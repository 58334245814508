import transition from "crayon-transition";
import animate from "crayon-animate";
import svelte from "crayon-svelte";
import crayon from "crayon";
import { captureException } from "@sentry/browser";

import Auth from "./pages/auth/index";
import Settings from "./pages/settings/index";
import FAQ from "./pages/settings/FAQ";
import Link from "./pages/settings/link";
import TOS from "./pages/settings/TOS";
import PrivacyPolicy from "./pages/settings/PrivacyPolicy";
import Login from "./pages/auth/login";
import LicensePlateLogin from "./pages/auth/license";
import SignUp from "./pages/auth/signup";
import Home from "./pages/index";
import Discover from "./pages/discover/index";
import Profile from "./pages/profile/index";
import Plans from "./pages/plans/index";
import PlanInfo from "./pages/plans/info";
import PlanWorkouts from "./pages/plans/workouts";
import Athletes from "./pages/athletes/index";
import AthleteInfo from "./pages/athletes/info";
import AthletePlans from "./pages/athletes/plans";
import Workouts from "./pages/workouts/index";
import WorkoutInfo from "./pages/workouts/info";
import WorkoutVideo from "./pages/workouts/video";

import { getCachedValue, setCachedValue } from "./helpers";
import init from "./init";
init();

const outlet = document.body;
const app = crayon.create();

app.use(svelte.router(outlet));
app.use(transition.loader());
app.use(
    animate.defaults({
        name: transition.fade,
        duration: 300,
    })
);
app.use(
    animate.routes([
        // { from: "/**", to: "/", name: transition.slideDown },
        // { from: "/", to: "/", name: transition.slideUp },
        // { from: "/", to: "/login", name: transition.slideUp },
        // { from: "/", to: "/signup", name: transition.slideUp },
        // { from: "/login", to: "/", name: transition.slideUp },
        // { from: "/signup", to: "/", name: transition.slideUp },
        // { from: "/discover", to: "/plans", name: transition.slideLeft, duration: 600 },
        // { from: "/discover", to: "/athletes", name: transition.slideLeft, duration: 600 },
        // { from: "/discover", to: "/workouts", name: transition.slideLeft, duration: 600 },
        // { from: "/plans", to: "/discover", name: transition.slideRight, duration: 600 },
        // { from: "/athletes", to: "/discover", name: transition.slideRight, duration: 600 },
        // { from: "/workouts", to: "/discover", name: transition.slideRight, duration: 600 },
    ])
);

app.path("/splash", (req, res) => {
    req.mount(Auth, { req, nav: app });
});

const authPages = crayon.group("", group => {
    group.use(async (req, res, next) => {
        try {
            const search = window.location.search;
            if (search) {
                const args = search
                    .toString()
                    .replace("?", "")
                    .split("&");
                for (const arg of args) {
                    const [name, value] = arg.split("=");
                    if (name === "accessToken" && value.length > 60) {
                        await setCachedValue("accessToken", value);
                    }
                }
            }
        } catch (err) {}
        try {
            const data = await getCachedValue("accessToken");
            if (!data) {
                req.redirect("/splash");
            }
        } catch (err) {
            console.error(err);
            captureException(err);
            req.redirect("/splash");
        }
    });

    const mount = async (req, component, props) => {
        const accessToken = await getCachedValue("accessToken");
        return req.mount(component, {
            req,
            nav: app,
            accessToken,
            ...(props || {}),
        });
    };

    group.path("/", async (req, res) => await mount(req, Home));
    group.path("/discover", async (req, res) => await mount(req, Discover));
    group.path("/plans", async (req, res) => await mount(req, Plans));
    group.path("/athletes", async (req, res) => await mount(req, Athletes));
    group.path("/workouts", async (req, res) => await mount(req, Workouts));
    group.path("/profile", async (req, res) => await mount(req, Profile));
    group.path("/settings", (req, res) =>
        req.mount(Settings, { req, nav: app })
    );
    group.path(
        "/plan/:id",
        async (req, res) =>
            await mount(req, PlanInfo, { planId: req.params.id })
    );

    group.path(
        "/plan/:id/workouts",
        async (req, res) =>
            await mount(req, PlanWorkouts, { planId: req.params.id })
    );

    group.path(
        "/athlete/:id",
        async (req, res) =>
            await mount(req, AthleteInfo, { athleteId: req.params.id })
    );

    group.path(
        "/athlete/:id/plans",
        async (req, res) =>
            await mount(req, AthletePlans, { athleteId: req.params.id })
    );

    group.path(
        "/workout/:id",
        async (req, res) =>
            await mount(req, WorkoutInfo, { workoutId: req.params.id })
    );

    group.path(
        "/workout/:id/video",
        async (req, res) =>
            await mount(req, WorkoutVideo, { workoutId: req.params.id })
    );
});

app.use(authPages);
app.path("/settings/privacy", (req, res) =>
    req.mount(PrivacyPolicy, { req, nav: app })
);
app.path("/settings/tos", (req, res) => req.mount(TOS, { req, nav: app }));
app.path("/settings/faq", (req, res) => req.mount(FAQ, { req, nav: app }));
app.path("/settings/link", (req, res) => req.mount(Link, { req, nav: app }));
app.path("/login", (req, res) => req.mount(Login, { req, nav: app }));
app.path("/login/code", (req, res) =>
    req.mount(LicensePlateLogin, { req, nav: app })
);
app.path("/signup", (req, res) => req.mount(SignUp, { req, nav: app }));

app.load();

window.app = app;

export default app;
