<script>
    import axios from "axios";
    import { onMount, createEventDispatcher, tick } from "svelte";
    import { captureException } from "@sentry/browser";

    import { apiBaseUrl } from "../config";
    import {
        sleep,
        getRefs,
        getCachedValue,
        setCachedValue,
        setCurrentError,
    } from "../helpers";
    import Spinner from "./Spinner";

    export let path;
    export let loading = false;
    export let postData = null;
    export let cacheKey;
    export let getData = data => data;
    export let method = "GET";

    export let mockData = null;
    export let mockDelay = 250;

    const refs = getRefs();
    const dispatch = createEventDispatcher();

    let isLoading = true;
    let data = null;
    let error = null;

    onMount(async () => {
        if (mockData) {
            await sleep(mockDelay);
            data = getData(mockData);
            isLoading = false;
            return;
        }
        const url = `${apiBaseUrl}${path}`;
        try {
            let newValue;
            // TODO: Reenable caching?
            // let newValue = cacheKey && (await getCachedValue(cacheKey));
            if (!newValue) {
                let accessToken = await getCachedValue("accessToken");
                const response = await axios({
                    url,
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    method: method || "GET",
                    data: method !== "GET" ? postData : undefined,
                });
                if (response.data.result) {
                    data = getData(response.data.result);
                    // TODO: Reenable caching?
                    // cacheKey && (await setCachedValue(cacheKey, data));
                } else if (response.data.error) {
                    error = response.data.error;
                }
            } else {
                await tick();
                data = newValue;
            }
        } catch (err) {
            captureException(err);
            setCurrentError(err, refs);
            if (err.data && err.data.error) {
                error = err.data.error;
            } else {
                error = err;
            }
        }
        if (data) {
            dispatch("loaded", data);
        } else {
            dispatch("error", error);
        }
        isLoading = false;
    });
</script>

{#if !(isLoading || loading) && data}
    <slot name="data" {data} />
{/if}
{#if error}
    <slot name="error" {error}>An error has occurred: {error}</slot>
{/if}
{#if isLoading || loading}
    <slot name="loading" {isLoading}>
        <Spinner />
    </slot>
{/if}
