<script>
    import { onMount, createEventDispatcher } from "svelte";
    import { fade } from "svelte/transition";
    import {
        focusRef,
        isInViewport,
        sleep,
        getRefs,
        initAria,
    } from "../../helpers";

    const dispatch = createEventDispatcher();

    const refs = getRefs();
    export let refKey;
    export let scrollParentKey = "";
    export let autofocus = false;
    export let className = "";
    export let left = "";
    export let right = "";
    export let up = "";
    export let down = "";
    export let marginRight = false;

    let focused = false;
    let offsetWidth;

    onMount(async () => {
        if (autofocus) {
            await sleep(100);
            refs[refKey] && refs[refKey].focus();
        }
    });
    const { label, labelledBy, asLabel, asHidden } = initAria();
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.button {
  padding: 0;
  margin: 0;
  position: relative;
  object-fit: cover;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: none;
  border: none;
  outline: none;
  border-radius: 8px;
  transition: all 0.1s ease-in-out; }
  .button:focus {
    -webkit-transform: scale(1.1);
    border: none;
    outline: none; }
  .button.focused {
    z-index: 1000; }
  .button.margin {
    margin-right: 24px; }

/*# sourceMappingURL=Button.svelte.css.map */</style>

<button
    class={`button ${className}`}
    aria-label={$label}
    aria-labelledby={$labelledBy}
    class:focused
    class:margin={marginRight}
    on:click
    type="button"
    transition:fade
    bind:offsetWidth
    on:keyup={event => {
        if (event.key === 'Enter' && refs[refKey]) {
            refs[refKey].style.transform = '';
        }
        if (event.key === 'ArrowDown') {
            focusRef(refs, down);
        }
        if (event.key === 'ArrowUp') {
            focusRef(refs, up);
        }
        if (event.key === 'ArrowLeft') {
            focusRef(refs, left);
        }
        if (event.key === 'ArrowRight') {
            focusRef(refs, right);
        }
    }}
    on:keydown={event => {
        if (event.key === 'Enter' && refs[refKey]) {
            refs[refKey].style.transform = 'scale(1.05)';
        }
        if (event.key === 'ArrowDown' && refs[down]) {
            event.preventDefault();
        }
        if (event.key === 'ArrowUp' && refs[up]) {
            event.preventDefault();
        }
        if (event.key === 'ArrowLeft' && refs[left]) {
            event.preventDefault();
        }
        if (event.key === 'ArrowRight' && refs[right]) {
            event.preventDefault();
        }
    }}
    on:focusin={async event => {
        focused = true;
        if (!isInViewport(refs[refKey]) && refs[scrollParentKey]) {
            const rect = refs[refKey].getBoundingClientRect();
            refs[scrollParentKey].scrollTo({
                left:
                    refs[scrollParentKey].scrollLeft +
                    (rect.left <= 0 ? -1 : 1) * offsetWidth,
                behavior: 'smooth',
            });
        }
        dispatch('focus', event);
    }}
    on:focusout={event => {
        focused = false;
        dispatch('blur', event);
    }}
    bind:this={refs[refKey]}>
    <slot {focused} {asLabel} {asHidden} />
</button>
