import { init as initSentry } from "@sentry/browser";

export default () => {
    initSentry({
        dsn: "https://100e5762516542fdb5b7fa43aa7ca65b@sentry.io/2499260",
        enabled: !!process.env.ENABLE_SENTRY,
        release: process.env.COMMIT_REF,
    });

    if (typeof tizen === "undefined") return;

    const keys = [
        "ArrowLeft",
        "ArrowUp",
        "ArrowRight",
        "ArrowDown",
        "Enter",
        "Back",
        "MediaPlay",
        "MediaPause",
        "MediaRewind",
        "MediaTrackPrevious",
        "MediaTrackNext",
        "MediaFastForward",
        // "ChannelUp",
        // "ChannelDown",
        // "ChannelList",
        "ColorF0Red",
        "ColorF1Green",
        "ColorF2Yellow",
        "ColorF3Blue",
    ];

    const supportedKeys = tizen.tvinputdevice
        .getSupportedKeys()
        .filter(key => keys.indexOf(key.name) >= 0)
        .map(key => key.name);
    tizen.tvinputdevice.registerKeyBatch(supportedKeys);
};
