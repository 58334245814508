<script>
    // import { onMount, tick } from "svelte";

    import {
        sleep,
        getKeyUpHandler,
        getKeyDownHandler,
        isVoiceEnabled,
        getRefs,
    } from "../helpers";

    export let refKey;
    export let up = "";
    export let down = "";
    export let left = "";
    export let right = "";

    const refs = getRefs();
</script>

<p
    class="hidden-intro"
    tabindex="-1"
    bind:this={refs[refKey]}
    on:keyup={event => {
        if (event.key === 'Enter') {
            document.body.focus();
            refs[refKey].focus();
        }
        getKeyUpHandler(refs, { up, down, left, right })(event);
    }}
    on:keydown={event => {
        getKeyDownHandler(refs, { up, down, left, right })(event);
    }}>
    <slot />
</p>
