<script>
    import { onMount, tick } from "svelte";

    import {
        initFocus,
        initRefs,
        getEntryFromFeatured,
        getPreviewData,
        getCarouselEntryFromWorkout,
        setVoiceIntro,
        sleep,
        isVoiceEnabled,
    } from "../helpers";
    import {
        getUserProfile,
        getPlanInfo,
        getFeatured,
        getPreviews,
    } from "../api";
    import Content from "../components/Content";
    import Spinner from "../components/Spinner";
    import HorizontalList from "../components/list";
    import Container from "../components/container";
    import Button from "../components/Button";
    import Carousel from "../components/carousel";

    export let nav;

    let loading = true;
    let featured = [];
    let currentPlan = null;

    const onNavigate = url => nav.navigate(url);

    let refs = initRefs();

    onMount(async () => {
        const profile = await getUserProfile();
        if (profile.currentPlanId) {
            currentPlan = await getPlanInfo(profile.currentPlanId);
        } else {
            featured = await getFeatured();
        }
        loading = false;
        await tick();
        await sleep(250);
        if (!isVoiceEnabled()) {
            refs["content"].focus();
        } else {
            refs["voiceIntro"].focus();
        }
    });
    const asIntro = setVoiceIntro(false);
    initFocus(refs, "content");
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.container {
  display: grid;
  grid-template-areas: "title" "list" "more";
  grid-template-rows: auto auto 1fr;
  grid-template-columns: 1fr;
  align-items: center;
  align-content: center;
  justify-items: center;
  box-sizing: border-box;
  height: 100%; }

.title {
  grid-area: title;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.8);
  font-family: Lato, sans-serif;
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #d9d9d9;
  margin: 80px 0;
  flex: 0 0 auto; }

.list {
  grid-area: list; }

.more {
  grid-area: more; }

/*# sourceMappingURL=index.svelte.css.map */</style>

<Container
    {nav}
    title="My Fitplan"
    topKey="content"
    backgroundImage="https://images.fitplanapp.com:443/tr:w-1200/web/hero/mike-desktop-banner.png"
    activeView="home">
    <div class="container">
        {#if currentPlan}
            <p class="hidden-intro" use:asIntro aria-live="assertive">
                These are the workouts from your active plan. {currentPlan.name}.
                By {currentPlan.athleteFirstName} {currentPlan.athleteLastName}.
                Press any arrow key to select a workout.
            </p>
        {:else}
            <p class="hidden-intro" use:asIntro aria-live="assertive">
                Loading your fitplans.
            </p>
        {/if}
        {#if loading}
            <Spinner />
        {:else if currentPlan}
            <Carousel
                entries={currentPlan.basicWorkouts.map(getCarouselEntryFromWorkout)}
                onStart={workout => {
                    if (workout.mobileOnly === true || workout.available === false) {
                        nav.navigate(`/settings/link?link=workout-${workout.id}`);
                        return;
                    }
                    if (workout.completed) {
                        nav.navigate(`/workout/${workout.id}`);
                    } else {
                        nav.navigate(`/workout/${workout.id}/video`);
                    }
                }}
                historyKey="workoutId"
                up="nav"
                refBase="content"
                {nav} />
        {:else}
            <div class="title">
                You are not on a follow-along Fitplan, try these Fitplans 👇
            </div>
            <div class="list">
                <HorizontalList
                    entries={featured.map(getEntryFromFeatured)}
                    {onNavigate}
                    refBase={'content'}
                    up="nav"
                    down="more" />
            </div>
            <div class="more">
                <Button
                    refKey="more"
                    up="content"
                    on:click={() => nav.navigate('/discover')}>
                    Discover More
                </Button>
            </div>
        {/if}
    </div>

</Container>
