<script>
    import { onDestroy, tick } from "svelte";
    import { captureException } from "@sentry/browser";
    import Hls from "hls.js";

    import DataLoader from "../../components/DataLoader";
    import Completed from "../../components/video/Completed";
    import Video from "../../components/video";
    import Image from "../../components/Image";
    import {
        initRefs,
        setCurrentError,
        getStreamFromWorkout,
        getPosterFromWorkout,
        getGlobalKeyUpHandler,
        getGlobalKeyDownHandler,
        replaceLocation,
    } from "../../helpers";
    import { finishWorkout } from "../../api";

    export let nav;
    export let accessToken;
    export let workoutId;

    let refs = initRefs();

    let loading = true;
    let workout;
    let videoUrl;
    let posterUrl;

    let completed = false;
    let startTime;
    let finishTimer;
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.container {
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh; }

.preview {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  height: 100vh;
  transition: opacity 0.2s ease-in-out;
  opacity: 1;
  background: black; }

:global(.fitplan-workout-video) {
  height: 100vh;
  transition: opacity 0.5s ease-in-out; }

/*# sourceMappingURL=video.svelte.css.map */</style>

<div class="container">
    {#if posterUrl}
        <div class="preview">
            <Image src={posterUrl} />
        </div>
    {/if}

    <Video
        {nav}
        up={'nav.back'}
        showBack
        className="fitplan-workout-video"
        {videoUrl}
        {loading}
        bind:completed
        bind:startTime />

    <DataLoader
        path={`/v1/television/workouts/details?workoutId=${workoutId}`}
        {accessToken}
        cacheKey={`workout-${workoutId}`}
        on:loaded={async workoutInfo => {
            if (workoutInfo.detail.mobileOnly === true || workoutInfo.detail.available === false) {
                replaceLocation(nav, `/settings/link?link=workout-${workoutInfo.detail.id}`);
                return;
            }
            workout = workoutInfo.detail;
            videoUrl = getStreamFromWorkout(workoutInfo.detail);
            posterUrl = getPosterFromWorkout(workoutInfo.detail);
            await tick();
        }}
        on:error={() => (loading = false)}
        {loading}>
        <div slot="loading" />
    </DataLoader>

    {#if completed && workout}
        <Completed
            {nav}
            refKey="completed"
            {workout}
            {startTime}
            endTime={new Date()}
            stats={[{ value: workout.expectedDuration, text: 'minutes' }, { value: workout.expectedDuration * 4, text: 'calories' }]} />
    {/if}
</div>

<svelte:window
    on:keydown={getGlobalKeyDownHandler()}
    on:keyup={getGlobalKeyUpHandler(() => window.history.back())} />
