<script>
    import { afterUpdate } from "svelte";
    import { useAria } from "../../helpers";

    export let entry;
    export let focused;

    const { asLabel } = useAria();
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity 0.2s ease-in-out;
  opacity: 0; }
  .mask.img {
    background-color: rgba(17, 18, 19, 0.55); }
  .mask.text {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 55%, rgba(17, 18, 19, 0.6) 70%, rgba(17, 18, 19, 0.8)); }
  .mask.active {
    opacity: 1; }

.img-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  border-radius: 8px; }
  .img-container img {
    height: 300px;
    object-fit: cover;
    object-position: center; }

.title {
  font-family: Lato, sans-serif;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #fff;
  font-family: Barlow, sans-serif;
  font-stretch: condensed;
  color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 24px 32px 24px;
  text-align: left; }

.subtitle {
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #fff;
  letter-spacing: 1.33px;
  color: #ededed;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  padding: 0 12px 12px 24px;
  transition: opacity 0.35s ease-in-out;
  opacity: 0;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap; }
  .subtitle.active {
    opacity: 1; }

.mobile-only {
  position: absolute;
  right: 20px;
  top: 20px;
  height: 28px;
  border-radius: 4px;
  background-color: #fed600;
  padding: 3px 8px 0 8px;
  font-family: Lato, sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #fff;
  color: #262626; }

/*# sourceMappingURL=Content.svelte.css.map */</style>

<div class="img-container">
    <img src={entry.imageUrl} alt={entry.title} />
    <div class="mask text" class:active={true} />
    <div class="title" use:asLabel={0} class:active={focused}>
        {entry.title}
    </div>
    {#if entry.subtitle}
        <div class="subtitle" use:asLabel={1} class:active={focused}>
            {entry.subtitle}
        </div>
    {/if}
    <div class="mask img" class:active={!focused} />
    {#if entry.mobileOnly}
        <div class="mobile-only" use:asLabel={4}>Mobile Only</div>
    {/if}
</div>
