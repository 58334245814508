<script>
    import {} from "svelte";

    import { getRefs } from "../../helpers";
    import DataLoader from "../DataLoader";
    import Spinner from "../Spinner";
    import List from "./List";

    export let nav;
    export let refBase;
    export let apiUrl;
    export let cacheKey;
    export let up;
    export let down;
    export let getEntry;
    export let getData;

    const refs = getRefs();
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.container {
  padding-left: 96px;
  padding-top: 30px; }

/*# sourceMappingURL=index.svelte.css.map */</style>

<DataLoader path={apiUrl} {cacheKey} {getData}>
    <div class="container" slot="data" let:data>
        <List
            {up}
            {down}
            {nav}
            {refBase}
            entries={data.map((entry, index) => ({ ...getEntry(entry) }))}
            let:entry
            let:focused>
            <slot {entry} {focused} />
        </List>
    </div>
    <span slot="loading">
        <Spinner display="grid" />
    </span>
</DataLoader>
