<script>
    import { onMount, afterUpdate, onDestroy, tick } from "svelte";
    import Hls from "hls.js";

    import Video from "./video";
    import Image from "./Image";
    import { sleep, setCurrentError } from "../helpers";
    import { minimumBitRate } from "../config";

    export let nav;
    export let left;
    export let videoUrl;
    export let previewUrl;

    let loading = true;

    let previewLoaded = false;
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.preview {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  transition: opacity 0.2s ease-in-out;
  opacity: 1;
  z-index: -1; }

:global(.fitplan-preview-video) {
  height: 100vh; }

/*# sourceMappingURL=PreviewVideo.svelte.css.map */</style>

<div class="preview">
    <Image src={previewUrl} on:loaded={() => (previewLoaded = true)} />
</div>

<Video {nav} className="fitplan-preview-video" {videoUrl} {left} isPreview />
