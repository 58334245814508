<script>
    import { onDestroy, afterUpdate, onMount, tick } from "svelte";

    import {
        addVideoLogger,
        initHls,
        getVideoKeyUpHandler,
    } from "../../helpers";
    import Controls from "./Controls";
    import Spinner from "../Spinner";

    export let nav;
    export let up;
    export let down;
    export let left;
    export let right;
    export let showBack;
    export let videoUrl;
    export let completed;
    export let startTime;
    export let isPreview = false;
    export let className = "";

    let video;
    let duration;
    let buffered;
    let seekable;
    let played;
    let seeking;
    let ended;
    let currentTime = 0;
    let playbackRate = 1;
    let paused = false;
    let volume = 0.5;

    let hls = null;
    let loading = true;
    let wideVideo = false;
    let videoPresent = false;

    const loadVideo = async () => {
        videoPresent = true;
        hls = initHls(videoUrl, video);
    };

    onMount(async () => {
        await tick();
        addVideoLogger(video);
        if (videoUrl) {
            await loadVideo();
        }
    });

    afterUpdate(async () => {
        if (!videoPresent && videoUrl) {
            await loadVideo();
        }
        if (isPreview && videoPresent && video.videoWidth > 540) {
            if (!wideVideo && video.videoWidth > video.videoHeight + 10) {
                wideVideo = true;
            }
        }
    });

    onDestroy(() => {
        hls && hls.destroy();
    });
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.video {
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  z-index: 1000; }
  .video.active {
    opacity: 1; }
  .video.wideVideo {
    -webkit-transform: translateX(-25%); }

.spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

/*# sourceMappingURL=index.svelte.css.map */</style>

<video
    class={`video ${className}`}
    class:active={!loading && !completed}
    class:wideVideo={wideVideo && isPreview}
    bind:this={video}
    bind:duration
    bind:buffered
    bind:seekable
    bind:paused
    bind:played
    bind:seeking
    bind:ended
    bind:currentTime
    bind:playbackRate
    bind:volume
    on:canplaythrough={event => {
        loading = false;
        if (!paused) {
            video.play();
        }
    }}
    on:ended={() => {
        video.pause();
        completed = true;
    }}
    on:play={async () => {
        if (completed) {
            video.currentTime = 0;
            video.play();
            completed = false;
        }
        if (!startTime) {
            startTime = new Date();
        }
    }} />

<Controls
    {nav}
    {up}
    {down}
    {left}
    {right}
    {showBack}
    {isPreview}
    {completed}
    bind:paused
    bind:loading
    bind:seekable
    bind:seeking
    bind:video
    bind:duration
    bind:currentTime
    on:play={() => {
        paused = false;
        video.play();
    }}
    on:pause={() => {
        paused = true;
        video.pause();
    }}
    on:back={() => window.history.back()} />

<svelte:window on:keyup={getVideoKeyUpHandler(video)} />

{#if loading || seeking}
    <div class="spinner">
        <Spinner display="grid" />
    </div>
{/if}
