<script>
    import { initRefs } from "../../helpers";
    import HorizontalGallery from "../../components/HorizontalGallery";
    import Container from "../../components/container";
    import DataLoader from "../../components/DataLoader";
    import Banner from "../../components/Banner";

    export let nav;
    export let accessToken;

    let refs = initRefs();
</script>

<Container
    {nav}
    showBackButton
    title="Elite Trainers"
    topKey="gallery.0"
    backgroundImage="https://images.fitplanapp.com:443/tr:w-1200/web/hero/mike-desktop-banner.png">
    <DataLoader
        path="/v1/television/content?includeAthletes=true"
        getData={data => data.athletes}
        {accessToken}
        cacheKey="all-athletes">
        <div slot="data" let:data>
            <HorizontalGallery
                entries={data.map(athlete => ({
                    url: `/athlete/${athlete.id}`,
                    title: `${athlete.firstName} ${athlete.lastName}`,
                    subtitle: athlete.shortDescription,
                    imageUrl: `${athlete.imageTv}?tr=w-500`,
                }))}
                {nav} />
            <Banner />
        </div>
    </DataLoader>
</Container>
