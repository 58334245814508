<script>
    import { onMount } from "svelte";

    import {
        getHistoryFromPlan,
        getHistoryFromWorkout,
        initFocus,
        initRefs,
        setScrollParent,
    } from "../../helpers";
    import History from "../../components/profile";
    import Avatar from "../../components/profile/Avatar";
    import Stats from "../../components/profile/Stats";
    import Tabs from "../../components/profile/Tabs";
    import Content from "../../components/profile/Content";
    import Container from "../../components/container";
    import DataLoader from "../../components/DataLoader";

    export let nav;

    const refs = initRefs();

    let activeTab = "fitplans";

    const scrollParent = setScrollParent();

    initFocus(refs, "nav", 1000);
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.container {
  display: grid;
  height: 100%;
  width: 100vw;
  grid-template-areas: ". .    .     ." ". user stats stats" ". .    .     ." ". nav  .     ." ". .    .     ." "list list list  list" ". .    .     .";
  grid-template-columns: 96px 600px 750px 1fr;
  grid-template-rows: 132px 124px 80px 60px 10px 1fr 64px;
  align-items: center;
  overflow: hidden; }
  .container .user {
    grid-area: user; }
  .container .stats {
    grid-area: stats; }
  .container .nav {
    grid-area: nav; }
  .container .list {
    grid-area: list; }
  .container .list {
    align-self: stretch;
    overflow: scroll; }
    .container .list::-webkit-scrollbar {
      display: none; }

.text {
  font-family: Lato, sans-serif;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #bfbfbf;
  position: fixed;
  bottom: 60px;
  right: 96px; }

/*# sourceMappingURL=index.svelte.css.map */</style>

<Container
    {nav}
    title="My Profile"
    topKey="tabs"
    activeView="profile"
    backgroundImage="https://images.fitplanapp.com:443/tr:w-1920/web/hero/mike-desktop-banner.png">
    <DataLoader path="/v2/user/profile" cacheKey="user-profile">
        <div class="container" slot="data" let:data={profile}>
            <div class="user">
                <Avatar
                    iamgeUrl={profile.image}
                    name={`${profile.firstName} ${profile.lastName}`}
                    gender={profile.gender} />
            </div>
            <div class="stats">
                <Stats />
            </div>
            <div class="nav">
                <Tabs
                    bind:activeTab
                    autofocus
                    refBase="tabs"
                    up="nav"
                    down={`history-${activeTab === 'fitplans' ? 'plans' : 'workouts'}`} />
            </div>
            <div class="list" use:scrollParent>
                {#if activeTab === 'fitplans'}
                    <History
                        {nav}
                        refBase="history-plans"
                        up="tabs"
                        apiUrl={'/v1/television/user/plans'}
                        cacheKey={'user-fitplans'}
                        getEntry={getHistoryFromPlan}
                        getData={undefined}
                        let:entry
                        let:focused>
                        <Content {entry} {focused} />
                    </History>
                {:else}
                    <History
                        {nav}
                        refBase="history-workouts"
                        up="tabs"
                        apiUrl={'/v1/television/user/workouts?size=20'}
                        cacheKey={'user-workouts'}
                        getEntry={getHistoryFromWorkout}
                        getData={data => data.content}
                        let:entry
                        let:focused>
                        <Content {entry} {focused} />
                    </History>
                {/if}
            </div>
            <div class="text">All Data Saved Across Fitplan Platforms</div>
        </div>
    </DataLoader>
</Container>
