<script>
    import { onMount, tick } from "svelte";

    import { getRefs } from "../../helpers";
    import Button from "../grid/Button";

    const refs = getRefs();
    export let nav;
    export let refBase;
    export let up;
    export let down;
    export let entries;

    let currentIndex = 0;
    let focused = false;

    onMount(async () => {
        await tick();
        refs[refBase] = {
            focus: () => {
                const el = refs[`${refBase}.${currentIndex}`];
                el && el.focus();
            },
        };
    });
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.container {
  display: flex;
  flex-wrap: nowrap;
  flex-flow: row; }
  .container .entry {
    flex: 0 0 300px;
    margin-right: 24px; }

/*# sourceMappingURL=List.svelte.css.map */</style>

<div class="container">
    {#each entries as entry, index}
        <div class="entry">
            <Button
                on:click={() => nav.navigate(entry.url)}
                bind:refs
                refKey={`${refBase}.${index}`}
                left={entries[index - 1] ? `${refBase}.${index - 1}` : `${refBase}.${entries[entries.length - 1]}`}
                right={entries[index + 1] ? `${refBase}.${index + 1}` : `${refBase}.0`}
                {up}
                {down}
                on:focus={() => (currentIndex = index)}
                on:blur={() => {
                    if (currentIndex === index) {
                        focused = false;
                    }
                }}>
                <slot
                    {entry}
                    currentIndex={focused && currentIndex === index} />
            </Button>
        </div>
    {/each}
</div>
