<script>
    import {} from "svelte";

    import MaleIcon from "../icons/Male";
    import FemaleIcon from "../icons/Female";

    export let name;
    export let imageUrl;
    export let gender;
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.container {
  display: grid;
  grid-template-areas: "img name" "img gender";
  grid-template-rows: 48px 48px;
  grid-template-columns: 104px 1fr;
  grid-gap: 8px 30px; }
  .container .img {
    grid-area: img; }
  .container .name {
    grid-area: name; }
  .container .gender {
    grid-area: gender; }
  .container .img {
    border-radius: 52px;
    width: 104px;
    height: 104px; }
  .container .name {
    font-family: Lato, sans-serif;
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #fff;
    color: #f5f5f5; }
  .container .gender {
    font-family: Lato, sans-serif;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #fff;
    font-weight: 600;
    line-height: 1;
    color: #8c8c8c; }
    .container .gender .text {
      position: relative;
      top: -2px; }

/*# sourceMappingURL=Avatar.svelte.css.map */</style>

<div class="container">
    <object
        class="img"
        aria-label="No Profile picture found"
        data="/default-avatar.svg"
        type="image/svg+xml">
        <img src={imageUrl} alt="Your Profile Image" />
    </object>
    <div class="name">{name}</div>
    <div class="gender">
        {#if gender === 'male'}
            <MaleIcon />
            <span class="text">Male</span>
        {:else if gender === 'female'}
            <FemaleIcon />
            <span class="text">Female</span>
        {/if}
    </div>
</div>
