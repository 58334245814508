<script>
    import { onMount } from "svelte";
    import { captureException } from "@sentry/browser";

    import Button from "../Button";
    import Spinner from "../Spinner";
    import Error from "../Error";
    import CompletedIcon from "./icons/Completed";
    import { finishWorkout } from "../../api";
    import {
        getInfoImageFromWorkout,
        deleteCachedValue,
        saveWorkoutResult,
        sleep,
        setCurrentError,
        getRefs,
        replaceLocation,
    } from "../../helpers";

    export let nav;
    const refs = getRefs();
    export let refKey;
    export let workout;
    export let startTime;
    export let endTime;
    export let stats = [];

    let href = `/plan/${workout.planId}/workouts`;
    let title = "Awesome Workout!";
    let subtitle = `Day ${workout.offset} - ${workout.name}`;
    let imageUrl = getInfoImageFromWorkout(workout);
    let buttonText = "Finish Workout";

    let loading = true;

    onMount(async () => {
        try {
            await finishWorkout(workout, startTime, endTime);
        } catch (err) {
            captureException(err);
            try {
                await saveWorkout(workout, startTime, endTime);
            } catch (err) {}
            setCurrentError(err, refs);
        }
        await deleteCachedValue(`plan-${workout.planId}-details`);
        loading = false;
        await sleep(500);
        refs[refKey].focus();
    });
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.container {
  position: fixed;
  overflow: hidden;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  width: 1311px;
  height: 763px;
  border-radius: 24px;
  box-shadow: 0 0px 20px rgba(200, 200, 200, 0.2), 0 15px 50px rgba(17, 18, 19, 0.25);
  background-color: #111213;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  z-index: 2000; }
  .container.loaded {
    opacity: 1; }

.grid {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas: ".    .     ." "text image ." ".    .     .";
  grid-template-rows: 64px 1fr 64px;
  grid-template-columns: 1fr 626px 64px;
  align-items: center;
  justify-items: stretch; }
  .grid .text {
    grid-area: text; }
  .grid .image {
    grid-area: image; }

.icon {
  display: inline-block;
  margin-bottom: 32px;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%); }

.title {
  font-family: Lato, sans-serif;
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #fff;
  font-size: 48px;
  text-align: center;
  line-height: 1;
  color: #f5f5f5;
  margin-bottom: 24px; }

.subtitle {
  font-family: Lato, sans-serif;
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #fff;
  text-align: center;
  line-height: 1;
  letter-spacing: normal;
  color: #ffffff;
  opacity: 0.8;
  margin-bottom: 100px; }

.button {
  display: inline-block;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%); }

.image {
  position: relative;
  overflow: hidden;
  width: 626px;
  height: 626px; }
  .image img {
    position: absolute;
    height: 99%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    display: block;
    object-fit: cover; }
  .image .stats {
    position: absolute;
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: -3px;
    padding-bottom: 32px;
    display: grid;
    grid-gap: 4px 40px;
    grid-template-areas: ". .     .     ." ". val1  val2  ." ". stat1 stat2 .";
    grid-template-rows: 1fr auto auto;
    grid-template-columns: 1fr auto auto 1fr;
    box-shadow: inset 0px 0px 36px 8px #111213;
    background-image: linear-gradient(to bottom, rgba(17, 18, 19, 0) 60%, rgba(17, 18, 19, 0.3) 80%, #111213 100%); }
    .image .stats .val1 {
      grid-area: val1; }
    .image .stats .val2 {
      grid-area: val2; }
    .image .stats .stat1 {
      grid-area: stat1; }
    .image .stats .stat2 {
      grid-area: stat2; }
    .image .stats .val1,
    .image .stats .val2 {
      font-family: Lato, sans-serif;
      font-size: 40px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #fff;
      line-height: 1.2;
      text-align: center;
      color: #ffffff; }
    .image .stats .stat1,
    .image .stats .stat2 {
      opacity: 0.6;
      font-family: Lato, sans-serif;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #fff;
      font-weight: 500;
      text-align: center;
      color: #ffffff; }

/*# sourceMappingURL=Completed.svelte.css.map */</style>

<div class="container" class:loaded={!loading}>
    <div class="grid">
        <div class="text">
            <div class="icon">
                <CompletedIcon />
            </div>
            <div class="title">{title}</div>
            <div class="subtitle">{subtitle}</div>
            <div class="button">
                <Button
                    autofocus
                    {refKey}
                    on:click={() => replaceLocation(nav, href)}>
                    {buttonText}
                </Button>
            </div>
        </div>
        <div class="image">
            <img src={imageUrl} alt={title} />
        </div>
        <div class="image">
            <div class="stats">
                {#each stats as stat, index}
                    <div class={`val${index + 1}`}>{stat.value}</div>
                    <div class={`stat${index + 1}`}>{stat.text}</div>
                {/each}
            </div>
        </div>
    </div>
</div>
{#if loading}
    <Spinner />
{/if}
