<script>
    import Button from "../Button";
    import Container from "../container";
    import { initRefs, sleep, clearCache } from "../../helpers";

    import Arrow from "./arrow.png";

    export let nav;

    const refs = initRefs();

    const scrollUp = () => {
        if (scrollParent.scrollTop > 4) {
            scrollParent.scrollTo({
                top:
                    scrollParent.scrollTop < 600
                        ? 0
                        : scrollParent.scrollTop - 600,
                behavior: "smooth",
            });
        }
    };

    const scrollDown = () => {
        const content = contentEl.getBoundingClientRect();
        const parent = scrollParent.getBoundingClientRect();
        if (content.bottom > parent.bottom) {
            scrollParent.scrollTo({
                top: scrollParent.scrollTop + 600,
                behavior: "smooth",
            });
        }
    };

    let contentEl;
    let scrollParent;
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.container {
  position: fixed;
  top: 0;
  left: 400px;
  right: 410px;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden; }
  .container .content {
    padding-top: 110px;
    padding-bottom: 200px; }
  .container::-webkit-scrollbar {
    display: none; }

.up,
.down {
  position: absolute;
  right: 185px; }
  .up :global(.fitplan-page-btn),
  .down :global(.fitplan-page-btn) {
    width: 64px;
    height: 64px;
    border-radius: 32px;
    padding: 0; }

.up {
  top: 350px; }
  .up img {
    transform: rotateZ(180deg); }

.down {
  bottom: 350px; }
  .down img {
    margin-top: 2px; }

/*# sourceMappingURL=index.svelte.css.map */</style>

<Container
    {nav}
    topKey="up"
    showBackButton
    backgroundImage="https://images.fitplanapp.com:443/tr:w-1920/web/hero/mike-desktop-banner.png" />

<div class="container" bind:this={scrollParent}>
    <div class="content" bind:this={contentEl}>
        <slot />
    </div>
</div>

<div class="up">
    <Button
        className="fitplan-page-btn"
        refKey="up"
        on:click={() => scrollUp()}
        left="nav.back"
        down="down">
        <img src={Arrow} alt="Page Up" />
    </Button>
</div>

<div class="down">
    <Button
        className="fitplan-page-btn"
        refKey="down"
        on:click={() => scrollDown()}
        left="nav.back"
        up="up">
        <img src={Arrow} alt="Page Down" />
    </Button>
</div>
