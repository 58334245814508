<script>
    import { tick } from "svelte";
    import { captureException } from "@sentry/browser";

    import { sleep, fieldValidators } from "../../helpers";
    import Spinner from "../../components/Spinner";
    import Container from "../../components/container";
    import Input from "../../components/Input";
    import { INVALID_LOGIN } from "../../api/errors";
    import Logo from "./logo.png";

    import {
        login,
        initRefs,
        initFocus,
        setCachedValue,
        clearCache,
        setError,
        setCurrentError,
        setVoiceIntro,
        isVoiceEnabled,
        replaceLocation,
    } from "../../helpers";

    const onClick = async () => {
        if (pending) {
            return;
        }
        error = fieldValidators[remaining[0]](
            remaining[0] === "email" ? username : password
        );
        refs[remaining[0]].blur();
        refs[remaining[0]].focus();
        if (remaining.length === 1 && !error) {
            pending = true;
            try {
                const result = await login(username, password);
                await clearCache();
                await setCachedValue("accessToken", result, 365);
                replaceLocation(nav, "/");
            } catch (err) {
                if (err && err.serverMessage === INVALID_LOGIN) {
                    error = "Invalid email or password";
                } else {
                    captureException(err);
                    setCurrentError(err, refs, "nav");
                    console.error(err);
                }
            }
            pending = false;
            return;
        } else if (!error) {
            const [completedEl, ...rest] = remaining;
            completed = [...completed, completedEl];
            // Delay to make animation smoother
            await sleep(200);
            remaining = [...rest];
            await tick();
        }
        if (isVoiceEnabled()) {
            await tick();
            await sleep(200);
            const el = refs[`${remaining[0]}Error`];
            if (error && el) {
                el.focus();
            } else {
                refs["voiceLogin"].focus();
            }
        } else {
            refs[remaining[0]].focus();
        }
    };

    const onBack = () => {
        if (completed.length > 0) {
            const target = completed.slice(-1)[0];
            // Delay to make animation smoother
            remaining = [target, ...remaining];
            sleep(200).then(async () => {
                completed = completed.slice(0, completed.length - 1);
                await tick();
                refs[remaining[0]].focus();
            });
        } else {
            replaceLocation(nav, "/splash");
        }
    };

    let pending = false;
    let username = "";
    let password = "";
    let error = "";

    let usernameEl;
    let passwordEl;
    let buttonEl;

    export let nav;

    const refs = initRefs();
    initFocus(refs, "input", 200);

    refs["input"] = {
        focus: () => refs[remaining[0]].focus(),
    };

    let completed = [];
    let remaining = ["email", "password"];
    const asIntro = setVoiceIntro();
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.container {
  position: relative;
  height: 800px;
  width: 100%; }
  .container .submit {
    visibility: hidden; }

.logo {
  margin-top: 24px;
  display: inline-block;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%); }

.field {
  position: absolute;
  top: 32px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  font-family: Lato, sans-serif;
  font-size: 30px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #fff;
  text-align: center;
  transition: opacity 0.8s ease;
  opacity: 0; }
  .field.completed {
    opacity: 0; }
  .field.current {
    opacity: 1; }
  .field .title {
    margin-bottom: 32px; }
  .field .error {
    margin-top: 24px;
    font-family: Lato, sans-serif;
    font-size: 24px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #fc172f; }

/*# sourceMappingURL=login.svelte.css.map */</style>

<Container
    {nav}
    topKey="input"
    ariaTopKey="input"
    {onBack}
    showBackButton
    backgroundImage="https://images.fitplanapp.com:443/tr:w-1920/web/hero/mike-desktop-banner.png">
    <p class="hidden-intro" bind:this={refs['voiceLogin']} use:asIntro>
        {#if remaining.length > 1}
            Sign in to Fitplan. Press any arrow key to enter your email address.
        {:else}Press any arrow key to enter your password.{/if}
    </p>
    <form on:submit|preventDefault={onClick} novalidate>
        <div class="logo">
            <img src={Logo} alt={'Fitplan Logo'} />
        </div>
        <div class="container">
            <div
                class="field"
                class:current={remaining[0] === 'email'}
                class:completed={completed.indexOf('email') > -1}>
                <div class="title">Enter your email</div>
                <Input
                    bind:element={usernameEl}
                    tabindex={1}
                    up="nav"
                    type="email"
                    refKey="email"
                    autocomplete="off"
                    placeholder="Email Address"
                    bind:value={username} />
                {#if error}
                    <div
                        class="error"
                        tabindex="-1"
                        bind:this={refs['emailError']}>
                        {error}
                        <div class="hidden-intro">
                            . Press any arrow key to enter your email again.
                        </div>
                    </div>
                {/if}
            </div>
            <div
                class="field"
                class:current={remaining[0] === 'password'}
                class:completed={completed.indexOf('password') > -1}>
                <div class="title">Enter your password</div>
                <Input
                    bind:element={passwordEl}
                    up="nav"
                    tabindex={2}
                    type="password"
                    refKey="password"
                    placeholder="Password"
                    bind:value={password} />
                {#if error}
                    <div
                        class="error"
                        tabindex="-1"
                        bind:this={refs['passwordError']}>
                        {error}
                        <div class="hidden-intro">
                            . Press any arrow key to enter your password again.
                        </div>
                    </div>
                {/if}
            </div>
            <div class="submit">
                <button focusable="false" type="submit">Submit</button>
            </div>
        </div>
    </form>
</Container>
{#if pending}
    <Spinner />
{/if}
