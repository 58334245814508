<script>
    import { onMount, createEventDispatcher } from "svelte";
    import { fade } from "svelte/transition";
    import {
        focusRef,
        isInViewport,
        sleep,
        getRefs,
        initAria,
    } from "../../helpers";

    const dispatch = createEventDispatcher();

    const refs = getRefs();
    export let refKey;
    export let autofocus = false;
    export let className = "";

    let focused = false;
    let offsetWidth;

    const { label, labelledBy, asLabel, asHidden } = initAria();
</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins 
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
/* ====================================== */
.button {
  padding: 0;
  margin: 0;
  position: relative;
  object-fit: cover;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: none;
  border: none;
  outline: none;
  border-radius: 8px;
  transition: all 0.2s ease; }
  .button:focus {
    border: none;
    outline: none; }

/*# sourceMappingURL=Button.svelte.css.map */</style>

<button
    class={`button ${className}`}
    aria-label={$label}
    aria-labelledby={$labelledBy}
    class:focused
    on:click
    transition:fade
    bind:offsetWidth
    on:keyup={event => {
        if (event.key === 'Enter') {
            dispatch('mouseup');
        }
        if (event.key === 'ArrowDown') {
            dispatch('down');
        }
        if (event.key === 'ArrowUp') {
            dispatch('up');
        }
        if (event.key === 'ArrowLeft') {
            dispatch('left');
        }
        if (event.key === 'ArrowRight') {
            dispatch('right');
        }
    }}
    on:keydown={event => {
        if (event.key === 'Enter') {
            dispatch('mousedown');
        }
        if (event.key === 'ArrowDown') {
            event.preventDefault();
        }
        if (event.key === 'ArrowUp') {
            event.preventDefault();
        }
        if (event.key === 'ArrowLeft') {
            event.preventDefault();
        }
        if (event.key === 'ArrowRight') {
            event.preventDefault();
        }
    }}
    on:focusin={async event => {
        focused = true;
        dispatch('focus', event);
    }}
    on:focusout={event => {
        focused = false;
        dispatch('blur', event);
    }}
    bind:this={refs[refKey]}>
    <slot {focused} {asLabel} {asHidden} />
</button>
